<template>
  <div>
    <div style="display: flex; align-items: left; justify-content: left; padding-bottom: 25px">
      <span class="title-user-scr">Próximos eventos</span>
    </div>
    <CardEvent :request="upcomingEvents" />
  </div>
</template>


<script>
import adminReservationsService from '@/services/admin/reservations';

import CardEvent from '@/components/CardEvent.vue';

export default {
  components: {
    CardEvent,
  },
  data() {
    return {
      reservations: [],
      blockedDates: [],
      reservationsRequest: [],
      loading: false,
    };
  },
  created() {
    this.update();
  },
  computed: {
    requests() {
      return this.reservationsRequest.filter((item) => item.status === 'pending');
    },
    requestsCancel() {
      return this.reservationsRequest.filter((item) => item.status === 'canceled');
    },
    upcomingEventsToPay() {
      return this.reservationsRequest.filter((item) => {
        return item.status === 'accepted' && item.statusPayment === 'pending';
      });
    },
    eventsHeld() {
      return this.reservationsRequest.filter((item) => {
        const today = Number(this.$getDate().split('-')[2]);
        const date = Number(item.reservationDate.split('-')[2]);
        return item.status !== 'canceled' && date < today;
      });
    },
    upcomingEvents() {
      return this.reservationsRequest.filter((item) => {
      //  const today = Number(this.$getDate().split('-')[2]);
      //  const date = Number(item.reservationDate.split('-')[2]);
        const today = new Date();
        const date = new Date(item.reservationDate);
        //  return item.status !== 'cancel' && ((item.paymentValidated && item.statusPayment === 'paid') || (item.noPayment && item.status === 'accepted')) && date > today;
        return item.status !== 'canceled' && date > today;
      });
    },
    todayEvents() {
      return this.reservationsRequest.filter((item) => {
        const today = this.$formatShowDate(new Date());
        return item.status !== 'canceled' && today.includes(item.reservationDate);
      });
    },
  },
  methods: {
    update() {
      this.loading = true;
      this.getCalendar();
      this.getReservationsRequest();
      this.loading = false;
    },
    getCalendar() {
      adminReservationsService
        .fetchCalendar()
        .then((response) => {
          this.reservations = response.data.reservations;
          this.blockedDates = response.data.blockedDates;
        })
        .catch((error) => {
          if (error.response && [400, 409].includes(error.response.status)) {
            this.$swal('Error al actualizar reservación', error.response.data.message, 'warning');
          }
        });
    },
    getReservationsRequest() {
      adminReservationsService
        .search()
        .then((response) => {
          this.reservationsRequest = response.data;
        })
        .catch((error) => {
          if (error.response && [400, 409].includes(error.response.status)) {
            this.$swal('Error al obtener reservación', error.response.data.message, 'warning');
          }
        });
    },
    confirm(id) {
      adminReservationsService
        .reservationConfirm(id)
        .then(() => {
          this.update();
        })
        .catch((error) => {
          if (error.response && [400, 409].includes(error.response.status)) {
            this.$swal('Error al confirmar reservación', error.response.data.message, 'warning');
          }
        });
    },
    acceptToPay(id) {
      adminReservationsService
        .acceptToPay(id)
        .then(() => {
          this.update();
        })
        .catch((error) => {
          if (error.response && [400, 409].includes(error.response.status)) {
            this.$swal('Error al aceptar reservación', error.response.data.message, 'warning');
          }
        });
    },
    depositConfirm(id) {
      adminReservationsService
        .depositConfirm(id)
        .then(() => {
          this.update();
        })
        .catch((error) => {
          if (error.response && [400, 409].includes(error.response.status)) {
            this.$swal('Error al confirmar deposito de reservación', error.response.data.message, 'warning');
          }
        });
    },
    cancel(id) {
      adminReservationsService
        .cancel(id)
        .then(() => {
          this.update();
        })
        .catch((error) => {
          if (error.response && [400, 409].includes(error.response.status)) {
            this.$swal('Error al cancelar reservación', error.response.data.message, 'warning');
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>