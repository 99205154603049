<template>
<div>
  <div style="text-align:center; margin-bottom:25px">
  <span class="title-user-scr-b">Reporte de gastos Dzidzil-Ha</span>
  </div>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th 
          id=""
          class="title-table-col text-center"
          style="color: #00aff2">
            Concepto
          </th>
          <th 
          id=""
          class="title-table-col text-center"
          style="color: #00aff2">
            Importe
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in paymentItems" :key="item.name">
          <td class="text-center">{{ item.concept }}</td>
          <td class="text-center">{{ $currency.format(item.amount) }}</td>
        </tr>
        <tr>
          <td class="title-text-info text-center">Gasto total</td>
          <td class="title-card-event text-center">{{ $currency.format(total) }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  </div>
</template>

<script>
import adminSuppliersService from '@/services/admin/supplierPayments';

export default {
  // data() {
  //   return {
  //     spendinfo: [
  //       {
  //         name: 'Spend concept A',
  //         spend: 15500,
  //       },
  //     ],
  //   };
  // },
    data() {
    return {
    paymentItems: [],
    dates: [this.$dayjs().subtract(30, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')],
  }
  },
  created() {
    this.fetchPayments(this.dates);
  },
  computed: {
    total() {
      if (!this.paymentItems.length) return 0;
      const reducer = (a, b) => a + b.amount;
      return this.paymentItems.reduce(reducer, 0);
    },
    imageDefaul() {
      return require('@/assets/images/avatar_dz.jpg');
    },
  },
  methods: {
    fetchPayments(dates) {
      if (dates.length === 2) {
        this.dates = dates;
        const startDate = dates[0];
        const endDate = dates[1];
        adminSuppliersService.search({ startDate, endDate }).then((response) => {
          this.paymentItems = response.data;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>