 <template>
  <v-form ref="formSpend" v-model="validForm" lazy-validation>
    <v-text-field label="Concepto de pago" v-model="concept"></v-text-field>
    <v-autocomplete
      item-text="name"
      item-value="id"
      label="Tipo de pago"
      v-model="paymentTypeId"
      :items="paymentItems"
    ></v-autocomplete>
    <v-text-field label="Proveedor" v-model="supplier"></v-text-field>
    <v-textarea label="Descripción" filled outlined v-model="description"></v-textarea>
    <v-text-field prefix="$" suffix="MXN" label="Importe a pagar" v-model="amount"></v-text-field>
    <v-btn @click="savenewspend" depressed color="#00aff2" dark> Registrar pago </v-btn>
  </v-form>
</template>
 
<script>
import adminSuppliersService from '@/services/admin/supplierPayments';

export default {
  data: () => ({
    validForm: true,
    concept: '',
    description: '',
    paymentTypeId: '',
    supplier: '',
    amount: 0,
    paymentItems: [],
  }),
  mounted() {
    this.paymentTypes();
  },
  methods: {
    paymentTypes() {
      adminSuppliersService.paymentTypes().then((response) => {
        this.paymentItems = response.data;
      });
    },
    savenewspend() {
      const amount = this.amount * 100;
        if (!Number.isInteger(amount)) {
          this.$swal(
            'Registro de egreso',
            'El cargo debe tener a lo más dos decimales.',
            'warning'
          );
          return;
        }
      const data = {
        concept: this.concept,
        description: this.description,
        paymentTypeId: this.paymentTypeId,
        supplier: this.supplier,
        amount: amount,
      };
      if (this.$refs.formSpend.validate()) {
        adminSuppliersService
          .createPayment(data)
          .then(() => {
            this.concept = '';
            this.description = '';
            this.paymentTypeId = '';
            this.supplier = '';
            this.amount = 0;
            this.$emit('on-save');
            this.$swal('Pago creado', 'El pago realizado se ha registrado con éxito', 'success');
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              this.$swal('Error al registrar egreso', error.response.data.message, 'warning');
            }
          });
      }
    },
  },
};
</script>