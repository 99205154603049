import { apiClient } from '../index';

/**
 * [Crea una reservación]
 * @return {[type]}          [description]
 */
function create({ title, description, reservationDate, customerId, reservationHour, lapse, partyRoomId, allowPendingPymts }) {
  return apiClient.post('/v1/reservations', {
    title,
    description,
    reservationDate,
    customerId,
    reservationHour,
    lapse,
    partyRoomId,
    allowPendingPymts
  });
}

/**
 * [Modifica fecha de una reservación]
 * @return {[type]}  [description]
 */
 function update({ id, title, description, reservationDate, reservationHour, lapse, partyRoomId }) {
  return apiClient.patch(`/v1/reservations/update`, {
    id,
    title,
    description,
    reservationDate,   
    reservationHour,
    lapse, 
    partyRoomId  
  });
}

/**
 * [Acredita el pago de una reservación]
 * @param {number} id Identificador de la reservación
 * @return {[type]}          [description]
 */
function reservationConfirm(id) {
  return apiClient.patch(`/v1/reservations/${id}/payment/validate`);
}

/**
 * [Acredita el pago de un deposito de reservación]
 * @param {number} id Identificador de la reservación
 * @return {[type]}          [description]
 */
 function depositConfirm(id) {
  return apiClient.patch(`/v1/reservations/${id}/payment/deposit/validate`);
}

/**
 * [Confirma una reservación para que el usuario inicie el proceso de pago.]
 * @param {number} id Identificador de la reservación
 * @return {[type]}          [description]
 */
 function acceptToPay(id) {
  return apiClient.patch(`/v1/reservations/${id}/accept`);
}

/**
 * [Rechaza una reservación.]
 * @param {number} id Identificador de la reservación
 * @return {[type]}          [description]
 */
 function cancel(id) {
  return apiClient.patch(`/v1/reservations/${id}/cancel`);
}

/**
 * [Obtiene las reservaciones según los criterios de búsqueda]
 * @return {[type]}          [description]
 */
function search(reservationId, customerId, status, startDate, endDate) {
  return apiClient.get('/v1/reservations/search', {
    params: {
      reservationId,
      customerId,
      status,
      startDate,
      endDate,
    },
  });
}

/**
 * [Obtiene el calendario de reservaciones]
 * @return {[type]}          [description]
 */
function fetchCalendar() {
  return apiClient.get('/v1/reservations/calendar');
}

export default { create, update, reservationConfirm, depositConfirm, search, fetchCalendar, acceptToPay, cancel };
