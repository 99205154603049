<template>
  <div>
    <v-col v-for="(item, index) in shownCards" :key="index" cols="12" md="12" style="margin-bottom: 15px">
      <v-card color="white">
        <v-row>
          <v-col cols="4" align="center">
            <span class="title-user-scr-b" v-html="getMonth(item.reservationDate)"></span><br />
            <span class="title-modal" v-html="getDay(item.reservationDate)"></span> <br />
            <span class="room-name-text"> {{ item.room.title }} </span>
          </v-col>

          <v-divider vertical></v-divider>

          <v-col cols="6">
            <span class="title-card-event">{{ item.title.split(' ').slice(0, 6).join(' ') + '...' }}</span
            ><br />
            <span> {{ getHour(item.reservationHour) }} </span>
            a <span> {{ getHour(item.reservationHour + item.lapse) }} </span>
            <v-row style="display: flex">
              <v-col>
                <v-avatar size="25">
                  <img alt="" :src="item.customer.avatar || userImageDefault" />
                </v-avatar>

                <span> {{ item.customer.name.split(' ').slice(0, 2).join(' ') + '...' }} </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col sm="2" v-if="item.status === 'accepted'">
            <div style="padding-bottom: 10px">
              <v-btn
                :disabled="!requiredDeposit(item) || item.depositPaid"
                @click="confirmDeposit(item.id)"
                color="white"
                small
                fab
                height="30px"
                width="30px"
              >
                <v-icon color="primary">mdi-cash-plus</v-icon>
              </v-btn>
            </div>
            <div style="padding-bottom: 10px">
              <v-btn
                :disabled="!item.depositPaid && requiredDeposit(item)"
                @click="confirmReservation(item.id)"
                color="warning"
                small
                fab
                height="30px"
                width="30px"
              >
                <v-icon color="primary">mdi-cash-check</v-icon>
              </v-btn>
            </div>
            <ModalEditBooking @update="update" :reservation="item" />
            <div style="padding-top: 10px">
              <v-btn @click="cancel(item.id)" color="white" small fab height="30px" width="30px">
                <v-icon color="primary">mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-col>

          <v-col sm="2" v-else>
            <div   v-if="item.status !== 'paid'" style="padding-bottom: 10px">
              <v-btn @click="acceptRequest(item.id)" color="white" small fab height="30px" width="30px">
                <v-icon color="primary">mdi-check-circle</v-icon>
              </v-btn>
            </div>          
            <div style="padding-bottom: 10px">
              <v-btn @click="cancel(item.id)" color="white" small fab height="30px" width="30px">
                <v-icon color="primary">mdi-delete</v-icon>
              </v-btn>
            </div>
            <ModalEditBooking  v-if="item.status === 'paid'" @update="update" :reservation="item" />
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <div class="text-center">
      <v-pagination v-model="page" :length="length"></v-pagination>
    </div>
  </div>
</template>



<script>
import ModalEditBooking from '@/components/ModalEditBooking.vue';
export default {
  props: {
    request: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ModalEditBooking
  },
  data() {
    return {
      page: 1,
    };
  },
  methods: {
    getHour(hour) {
      return hour + ':00';
    },
    getMonth(date) {
      const meses = ['En.', 'Febr.', 'Mzo.', 'Abr.', 'My.', 'Jun.', 'Jul.', 'Agt.', 'Sept.', 'Oct.', 'Nov.', 'Dic.'];
      return meses[date.split('-')[1] - 1];
    },
    getDay(date) {
      return date.split('-')[2];
    },
    acceptRequest(id) {
      this.$emit('accept', id);
    },
    confirmDeposit(id) {
      this.$emit('deposit', id);
    },
    confirmReservation(id) {
      this.$emit('paid', id);
    },
    cancel(id) {
      this.$emit('cancel', id);
    },    
    requiredDeposit(item) {
      return !!item.depositPaymentLink;
    },
    update() {
      this.$emit('update');
    }
  },

  computed: {
    userImageDefault() {
      return require('@/assets/images/avatar_dz.jpg');
    },
    length() {
      return Math.ceil(this.request.length / 3);
    },

    shownCards() {
      const { page, request } = this;
      const number = 3; // Numero de elementos a mostrar por página
      return request.slice((page - 1) * number, page * number);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>