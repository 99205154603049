<template>
  <div>
    <div style="display: flex; align-items: center; justify-content: center">
      <v-img max-width="300" src="@/assets/booking_thanks.png"></v-img>
    </div>


    <p style="font-size: 18px; text-align: center; margin-top:25px"
      >Tu solicitud de reservación ha sido enviada con éxito, 
      te notificaremos en cuanto el administrador la acepte.</p>
    <v-btn
      block
      color="primary"
      large
      rounded
      link
      :to="{ name: 'events' }"
      style="margin-top: 25px"
      >Finalizar</v-btn
    >
  </div>
</template>

<script>
export default {};
</script>