<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-col cols="12">
          <v-btn color="primary" small fab dark v-bind="attrs" v-on="on">
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </v-col>
      </template>
      <v-card>
        <v-img class="white--text align-end" height="200px" :src="post.imageUrl"> </v-img>

        <v-card-title> {{ post.title }} </v-card-title>
        <v-card-subtitle> {{ $dayjs(post.createdAt).format('lll') }} </v-card-subtitle>

        <v-card-text class="text--primary">
          {{ post.description }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {},
  data: () => ({
    dialog: false,
  }),
};
</script>