<template>
  <div class="padding-card grey">
    <v-row>
      <v-col sm="6">
        <v-card class="padding-card">
          <p class="title-user-scr"> <v-icon> mdi-currency-usd-off </v-icon> Configuración de recargos</p>
          <v-form ref="formSettings" v-model="validForm" lazy-validation>
            <v-row>
              <v-col cols="12" sm="12">
                <v-select
                  v-model="surchargeType"
                  :rules="[rules.required]"
                  :items="['fixed', 'percentage']"
                  label="Tipo de cargo"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  :rules="surchargeType === 'fixed' ? rules.surchargeFixed : rules.surchargePercentage"
                  type="number"
                  min="0"
                  :max="surchargeType === 'percentage' ? 100 : 10000"
                  v-model="surchargeValue"
                  label="Valor del cargo"
                ></v-text-field>
              </v-col>
            </v-row>
            <br />
            <br />
            <p class="title-user-scr"> <v-icon> mdi-calendar-multiple-check </v-icon> Configuración de reservaciones</p>
            <v-text-field
              :rules="[rules.required]"
              type="number"
              min="1"
              v-model="reservationBlockSize"
              label="Bloque de horas para reservación"
            ></v-text-field>
          </v-form>
          <br />
          <br />
          <br />
          <v-card-actions>
            <v-btn @click="save" depressed color="#00aff2" dark>Actualizar</v-btn>
            <v-spacer></v-spacer>
            <div style="text-align: right">
              <span style="font-size: 10px"> Última actualización: {{ $dayjs(settings.updatedAt).format('ll') }}</span>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col sm="6">
        <v-form ref="formProfile" v-model="validFormProfile" lazy-validation>
          <v-card style="padding: 25px 25px 25px 25px">
            <p class="font-weight-medium text-h6" style="color: #00aff2">
              <v-icon> mdi-account-lock </v-icon> Editar perfil administrador
            </p>

            <UserAdminForm ref="editUser" :info-user="profile" @ready-to-save="readyToSave = $event" />
            <v-card-actions>
              <v-btn :disabled="!validFormProfile" @click="saveCurrentUser" depressed color="#00aff2" dark>
                Actualizar
              </v-btn>
              <v-spacer></v-spacer>
              <div style="text-align: right">
                <span style="font-size: 10px"> Última actualización: {{ $dayjs(profile.updatedAt).format('ll') }}</span>
              </div>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>

    <v-row>
      <!-- form-configuracion de plan de mantenimiento -->
      <v-col cols="12" sm="12">
        <v-card class="padding-card">
          <div style="display: flex">
            <p class="title-user-scr">
              <v-icon> mdi-cog-sync </v-icon> Configuración de plan de mantenimiento {{ new Date().getFullYear() }}
            </p>

            <ModalNewPlan @exit="close" />
          </div>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th id="" style="font-size: 14px" class="text-left">#</th>
                  <th id="" style="font-size: 14px" class="text-left">Concepto</th>
                  <th id="" style="font-size: 14px" class="text-left">Descripción</th>
                  <th id="" style="font-size: 14px" class="text-left">Día de vencimiento</th>
                  <th id="" style="font-size: 14px" class="text-left">Importe </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in months" :key="index">
                  <td style="width: 5%"> {{ item.id }} </td>
                  <td style="width: 30%">{{ item.concept }}</td>
                  <td style="width: 30%"> {{ item.description }} {{ item.paymentYear }} </td>
                  <td style="width: 20%"> {{ item.paymentDueDay }} </td>
                  <td style="width: 15%">
                    {{ $currency.format(item.amount) }}
                  </td>
                  <td style="width: 5%">
                    <ModalMaintenancePaymentsUsers :year="item.paymentYear" :maintenance-fee-id="item.id" />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12">
        <v-card class="padding-card">
          <p class="title-user-scr"> <v-icon> mdi-party-popper </v-icon> Configuración de salas de eventos </p>
          <v-autocomplete
            item-text="title"            
            return-object
            v-model="roomSelected"
            @change="newRoomFalse"
            :items="rooms"
            filled
            label="Selecciona un salón para editar"
          >
          </v-autocomplete>

          <p class="title-user-scr">Información del salón</p>
          <v-form ref="formRoom" v-model="validFormRoom" lazy-validation>
            <v-row>
              <v-col sm="6">
                <v-img alt="" :src="roomSelected.imageUrl" />
                <v-file-input
                  dense
                  ref="uploadImage"
                  v-model="image"
                  @change="selectFileRoom"
                  accept="image/*"
                  label="Cambia la imagen del salón"
                ></v-file-input>
                <v-text-field
                  dense
                  v-model="roomSelected.title"
                  :rules="[rules.required]"
                  label="Nombre del salón"
                ></v-text-field>
                <v-text-field dense v-model="roomSelected.subtitle" label="Resumen"></v-text-field>
                </v-col>
                <v-col sm="6">
                  <v-textarea
                  dense
                  v-model="roomSelected.description"
                  :rules="[rules.required]"
                  label="Descripción"
                  auto-grow
                  outlined
                  rows="12"
                ></v-textarea>
                 <v-checkbox
                  v-model="roomSelected.noPayment"
                  label="Reservar sin pago"
                  color="red"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  v-model="roomSelected.deposit"
                  label="Requiere deposito"
                  color="red"
                  hide-details
                  dense
                ></v-checkbox>
                <v-text-field
                  v-if="roomSelected.deposit"
                  :rules="[rules.required]"
                  prefix="$"
                  v-model="roomSelected.depositAmount"
                  suffix="MXN"
                  min="0"
                  type="number"
                  label="Monto de depósito"
                ></v-text-field>
                <v-text-field
                  prefix="$"
                  v-model="roomSelected.costAmount"
                  :rules="[rules.required]"
                  suffix="MXN"
                  min="100"
                  type="number"
                  label="Costo total"
                ></v-text-field>
                <v-text-field
                  :rules="[rules.required]"
                  type="number"
                  min="1"
                  v-model="roomSelected.lapse"
                  label="Bloque de horas para reservación"
                ></v-text-field>
              </v-col>                              
            </v-row>
          </v-form>
          <v-card-actions>
            <v-btn v-if="!newPartyRoom" @click="updateRoom" depressed color="#00aff2" dark>Actualizar</v-btn>
            <v-btn v-if="!newPartyRoom" @click="newRoom" color="primary" small fab dark><v-icon>mdi-plus</v-icon></v-btn>
            <v-btn v-if="newPartyRoom" @click="createNewRoom" depressed color="#00aff2" dark>Guardar</v-btn>
          
            <v-spacer></v-spacer>
            <div style="text-align: right">
              <span style="font-size: 10px">
                Última actualización: {{ $dayjs(roomSelected.updatedAt).format('ll') }}
              </span>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UserAdminForm from '../../components/UserAdminForm.vue';
import ModalNewPlan from '@/components/ModalNewPlan.vue';
import adminSettingsService from '@/services/admin/config';
import adminRoomsService from '@/services/admin/partyRooms';
import adminUserService from '@/services/users';
import adminScheduleMaintenanceService from '@/services/admin/maintenancePayments';
import ModalMaintenancePaymentsUsers from '@/components/ModalMaintenancePaymentsUsers.vue';

export default {
  components: {
    UserAdminForm,
    ModalMaintenancePaymentsUsers,
    ModalNewPlan,
  },
  data() {
    return {
      show1: false,
      show2: false,
      concept: '',
      description: '',
      paymentYear: 2021,
      paymentDueDay: 5,
      amount: 0,
      maintenanceFeeId: null,
      validFormSchedule: true,
      validForm: true,
      validFormProfile: true,
      months: [],
      settings: [],
      newPartyRoom: false,
      rules: {
        required: (value) => !!value || 'Required.',
        surchargePercentage: [
          (v) => !!v || 'Recargo es requerido',
          (v) => v <= 100 || 'El porcentaje debe estar entre 0 y 100',
          (v) => v >= 0 || 'Recargo debe ser mayor o igual a cero',
        ],
        surchargeFixed: [
          (v) => !!v || 'Recargo es requerido',
          (v) => v <= 100000 || 'El rango permitido es de 0 a 10000',
          (v) => v >= 0 || 'Recargo debe ser mayor o igual a cero',
        ],
        name: [
          (v) => !!v || 'Nombre is required',
          (v) => v.length <= 60 || 'Nombre debe ser menor a 60 caracteres ',
          (v) => v.length >= 2 || 'Nombre debe ser mayor a 2 caracteres ',
        ],
        min: (v) => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => `The email and password you entered don't match`,
        email: [(v) => !!v || 'E-mail is required', (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
      },
      surchargeType: '',
      surchargeValue: 0,
      reservationBlockSize: 1,
      profile: {
        username: '',
        email: '',
      },
      password: '',
      confirmPassword: '',
      rooms: [],
      validFormRoom: null,
      roomSelected: {},
      image: null,
    };
  },
  created() {
    this.fetch();
    this.fetchSchedulePayment();
    this.fetchProfile();
    this.fetchRooms();
  },
  methods: {
    selectFileRoom(file) {
      this.image = file;
    },
    updateRoom() {
      if (this.$refs.formRoom.validate()) {
        const depositAmount = this.roomSelected.depositAmount * 100;
        const costAmount = this.roomSelected.costAmount * 100;
        if (!Number.isInteger(depositAmount) || !Number.isInteger(costAmount)) {
          this.$swal(
            'Cobro de Salones',
            'El cobro de salones debe tener a lo más dos decimales, valide el cobro de deposito o el cobro total.',
            'warning'
          );
          return;
        }
        const formData = new FormData();

        if (this.image) {
          formData.append('image', this.image, this.image.name);
        }
        formData.append('title', this.roomSelected.title);
        formData.append('subtitle', this.roomSelected.subtitle);
        formData.append('description', this.roomSelected.description);
        formData.append('deposit', this.roomSelected.deposit);
        formData.append('noPayment', this.roomSelected.noPayment);
        formData.append('lapse', this.roomSelected.lapse);
        formData.append('depositAmount', depositAmount);
        formData.append('costAmount', costAmount);
        adminRoomsService
          .update(this.roomSelected.id, formData)
          .then(() => {
            this.$swal('Salón actualizado', 'Salón actualizado con éxito', 'success');
            this.fetchRooms();
            this.image = null;
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              this.$swal('Error al actualizar salón', error.response.data.message, 'warning');
            }
          });
      }
    },
    newRoom() {
      this.roomSelected = {
        deposit:false,
        noPayment:false,
        lapse: 1,
        depositAmount:0,
        costAmount:0,
        title:"",
        subtitle:"",
        description:""
      };
      this.newPartyRoom = true;
    },
    createNewRoom() {
      if (this.$refs.formRoom.validate()) {
        const depositAmount = this.roomSelected.depositAmount * 100;
        const costAmount = this.roomSelected.costAmount * 100;
        console.log(this.roomSelected);
       
        const formData = new FormData();

        if (this.image) {
          formData.append('image', this.image, this.image.name);
        }
        formData.append('title', this.roomSelected.title);
        formData.append('subtitle', this.roomSelected.subtitle);
        formData.append('description', this.roomSelected.description);
        formData.append('deposit', this.roomSelected.deposit);
        formData.append('noPayment', this.roomSelected.noPayment);
        formData.append('lapse', this.roomSelected.lapse);
        formData.append('depositAmount', depositAmount);
        formData.append('costAmount', costAmount);
        adminRoomsService
          .create(formData)
          .then(() => {
            this.$swal('Salón creado', 'Salón actualizado con éxito', 'success');
            this.fetchRooms();
            this.image = null;
            this.newRoomFalse();
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              this.$swal('Error al actualizar salón', error.response.data.message, 'warning');
            }
          });
      }
    },
    newRoomFalse(){
       this.newPartyRoom = false;
    },
    close() {
      this.dialog = false;
      this.fetchSchedulePayment();
    },
    fetchRooms() {
      adminRoomsService.fetch().then((response) => {
        this.rooms = response.data;
        this.rooms.forEach((item) => {
          item.costAmount = item.costAmount / 100;
          item.depositAmount = item.depositAmount / 100;
        });
        this.roomSelected = response.data[0];
      });
    },
    fetchSchedulePayment() {
      // const year = new Date().getFullYear();
      adminScheduleMaintenanceService.fetch().then((response) => {
        this.months = response.data;
      });
    },
    fetchProfile() {
      adminUserService.user().then((response) => {
        this.profile = response.data;
      });
    },
    saveCurrentUser() {
      console.log('savecurrentuser');
      if (this.$refs.formProfile.validate()) {
        const data = this.$refs.editUser.userData();
        adminUserService
          .updatePerfil(data.user)
          .then(() => {
            this.$swal('Perfil actualizado', 'Perfil actualizado con éxito', 'success');
            this.fetchProfile();
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              this.$swal('Error al actualizar perfil', error.response.data.message, 'warning');
            }
          });
      }
    },
    fetch() {
      adminSettingsService.fetch().then((response) => {
        this.settings = response.data;
        this.surchargeType = this.settings.surchargeType;
        this.surchargeValue = this.settings.surchargeValue;
        if (this.settings.surchargeType === 'fixed') {
          this.surchargeValue = this.settings.surchargeValue / 100;
        }
        this.reservationBlockSize = this.settings.reservationBlockSize;
      });
    },
    save() {
      if (this.$refs.formSettings.validate()) {
        let count = 0;
        if (
          this.surchargeType !== this.settings.surchargeType ||
          this.surchargeValue !== this.settings.surchargeValue / 100
        ) {
          let surchargeValue = this.surchargeValue;
          if (this.surchargeType === 'fixed') {
            surchargeValue = this.surchargeValue * 100;
            if (!Number.isInteger(surchargeValue)) {
              this.$swal('Cobro de recargos', 'El cobro de recargos debe tener a lo más dos decimales.', 'warninf');
              return;
            }
          }
          adminSettingsService
            .updateSurcharges(this.surchargeType, surchargeValue)
            .then(() => {
              this.$swal('Configuraciones', 'La configuración de recargo se a guardado correctamente.', 'success');
            })
            .catch((error) => {
              if (error.response && error.response.status === 400) {
                this.$swal('Error al actualizar configuraciones de recargo', error.response.data.message, 'warning');
              }
            });
        } else {
          count++;
        }

        if (this.reservationBlockSize !== this.settings.reservationBlockSize) {
          adminSettingsService
            .updateBlocksize(this.reservationBlockSize)
            .then(() => {
              this.$swal(
                'Configuraciones',
                'La configuración de ventana de reservación se a guardado correctamente.',
                'success'
              );
            })
            .catch((error) => {
              if (error.response && error.response.status === 400) {
                this.$swal('Error al actualizar ventana de reservación', error.response.data.message, 'warning');
              }
            });
        } else {
          count++;
        }

        if (count === 2) {
          this.$swal('Configuraciones', 'No hay valores a actualizar.', 'success');
        }
      }
    },   
  }
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>