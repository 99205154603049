<template>
  <v-app>
    <div class="padding-card-c grey">
      <v-card>
        <div class="padding-card-c">
          <NotificationForm @on-save="getNotifications" />
        </div>
      </v-card>
      <p class="title-user-scr-b" style="padding-top: 50px"> Últimas notificaciones enviadas </p>
      <v-col v-for="(item, i) in shownCards" :key="i" cols="12">
        <v-card color="white">
          <div class="d-flex justify-space-between">
            <v-list-item>
              <v-list-item-avatar>
                <img alt="" :src="imageDefaul" />
              </v-list-item-avatar>

              <v-list-item-content style="margin-left: 15px">
                <v-list-item-title class="title-text-info"> {{ item.title }} </v-list-item-title>
                <span> {{ item.description }} </span>
              </v-list-item-content>

              <v-list-item-action>
                <span style="font-size: 12px"> {{ $dayjs(item.createdAt).format('lll') }} </span>
              </v-list-item-action>
            </v-list-item>
          </div>
        </v-card>
      </v-col>
      <div class="text-center">
      <v-pagination v-model="page" :length="length"></v-pagination>
    </div>
    </div>
  </v-app>
</template>

<script>
import adminNotificationService from '@/services/admin/notifications';
import NotificationForm from '@/components/NotificationForm.vue';

export default {
  components: {
    NotificationForm,
  },
  created() {
    this.getNotifications();
  },
  computed: {
    imageDefaul() {
      return require('@/assets/images/avatar_dz.jpg');
    },
    length() {
      return Math.ceil(this.notifications.length / 5);
    },

    shownCards() {
      const { page, notifications } = this;
      const number = 5; // Numero de elementos a mostrar por página
      return notifications.slice((page - 1) * number, page * number);
    },
  },
  methods: {
    getNotifications() {
      adminNotificationService.fetch().then((response) => {
        this.notifications = response.data;
      });
    },
  },
  data() {
    return {
      page: 1,
      notifications: [],
    };
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>