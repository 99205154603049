<template>
  <v-dialog v-model="dialog" persistent max-width="1450px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="secondary" fab v-bind="attrs" v-on="on" height="50px" width="50px">
        <v-icon color="white">mdi-tools</v-icon>
      </v-btn>
    </template>
    <v-card>
      <div class="padding-card">
        <MaintenancePayments :customer-id="customerId" />
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false"> Cerrar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MaintenancePayments from '@/components/MaintenancePayments.vue';
export default {
  props: {
    customerId: {
      type: Number,
      required: true,
    },
  },
  components: {
    MaintenancePayments,
  },
  data: () => ({
    dialog: false,
  }),
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>