<template>
  <div>
    <div style="display: flex">
      <v-subheader class="title-user-scr-b">Subscrito a los planes:</v-subheader>

      <v-spacer></v-spacer>
    </div>
    <v-simple-table dense>
      <thead>
        <tr>
          <th class="text-left title-table-col">Concepto</th> 
          <th class="text-left title-table-col">Descripción</th>         
        </tr>
      </thead>
      <tbody>
        <tr v-for="(textField, index) in maintenplans" :key="index" class="text-fields-row">
          <td>{{ textField.concept }} </td> 
          <td>{{ textField.description }} </td>         
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import adminScheduleMaintenanceService from '@/services/admin/maintenancePayments';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);
dayjs.locale('es');

export default {
  props: {
    customerId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      maintenplans: [],      
    };
  },
  mounted() {
    this.fetch();
  },
  
  methods: {    
    fetch() {      
      adminScheduleMaintenanceService.fetchUserSuscriptions(this.customerId).then((response) => {
        console.log(response);
        this.maintenplans = response.data;
      });
    },    
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>
