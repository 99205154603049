import { apiClient } from '../index';

/**
 * [Crea un pago a proveedor
]
 * @return {[type]}          [description]
 */
function createPayment(data) {
  return apiClient.post('/v1/supplier-payments', data);
}

/**
 * [Encuentra los pagos a proveedores según los criterios de búsqueda]
 * Fecha de inicio (DD-MM-YYYY)
 * @return {[type]}          [description]
 */
 function search({ startDate, endDate, paymentTypeId, limit }) {
  return apiClient.get('/v1/supplier-payments/search', { 
    params: {
      startDate, endDate, paymentTypeId, limit
    } 
  });
}

/**
 * [Encuentra tipos de pagos]
 * Fecha de inicio (DD-MM-YYYY)
 * @return {[type]}          [description]
 */
 function paymentTypes() {
  return apiClient.get('/v1/supplier-payments/payment-types');
}

/**
 * [Actualiza un solón de fiestas]
 * @return {[type]}          [description]
 */
 function update(id, data) {
  return apiClient.put('/v1/supplier-payments/' + id, data);
}

export default { createPayment, search, paymentTypes, update};