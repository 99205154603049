<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-col cols="3">
          <v-btn color="primary" small fab dark v-bind="attrs" v-on="on">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="9">
          <v-list-item-title class="text" style="padding-top: 10px">
            Agregar un boletín
          </v-list-item-title>
        </v-col>
      </template>
      <v-card>
        <div class="padding-card">
          <p class="title-modal">Publicar nuevo boletín</p>
          <NewsletterForm @exit="close"/>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import NewsletterForm from "@/components/NewsletterForm.vue";

export default {
  components: {
    NewsletterForm,
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    close() {
      this.dialog = false;
    },
    validate() {},
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>