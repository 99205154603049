<template>
  <v-container fluid>
    <v-row>
      <v-col v-for="card in cards" :key="card.number" cols="12">
        <v-card color="primary" style="display: flex">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="white--text">**** **** **** {{ card.last4 }}</v-list-item-title>
              <v-list-item-title class="white--text" v-text="card.name"></v-list-item-title>
              <v-list-item-title class="white--text"
                >Agregado: {{ $dayjs(card.created_at * 1000).format('ll') }}</v-list-item-title
              >
              <v-list-item-title class="white--text">{{ card.brand }} - {{ card.card_type }}</v-list-item-title>
              <v-list-item-title class="white--text">{{ card.exp_month }} / {{ card.exp_year }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn dark elevation="2" icon>
                <v-icon @click="alertdeletecard" color="black"> mdi-trash-can-outline </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>

    <v-btn
      :loading="loading"
      block
      color="secondary"
      large
      rounded
      link
      :to="{ name: 'newcardsuser' }"
      style="margin-top: 25px"
    >
      {{ cards.length === 0 ? 'Agregar tarjeta' : 'Reemplazar tarjeta' }}</v-btn
    >
  </v-container>
</template>


<script>
import conektaService from '@/services/users/conekta';

export default {
  data: () => ({
    cards: [],
    loading: false,
  }),
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      conektaService.fetch().then((response) => {
        this.cards = response.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
        this.cards = [];
      });
    },
    alertdeletecard() {
      // Use sweetalert2
      this.$swal({
        title: '¿Deseas eliminar la tarjeta?',
        text: 'Se cancelarán los cobros automaticos y no podrás utilizarla para realizar tus pagos',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          conektaService.deleteCard().then((response) => {
            if (response.data.success) {
              this.$swal('Tarjeta eliminada', 'Se ha eliminado con éxito la tarjeta seleccionada', 'success');
            } else {
              this.$swal('Operación no existosa', 'Intentalo más tarde', 'error');
            }
          });
        } else {
          this.$swal('Operación cancelada', '', 'error');
        }
      });
    },
  },
};
</script>