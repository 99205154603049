<template>
  <div>
    <div v-for="(inc, i) in income" :key="i">
      <v-card style="margin-bottom: 15px">
        <v-list-item three-line>
          <v-list-item-avatar>
            <img alt="" :src="inc.customer.imageUrl || imageDefaul" /></v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              class="title-text-info"
               v-html="inc.customer.username"
            ></v-list-item-title>
            <v-list-item-subtitle> {{ inc.paymentMethod }} </v-list-item-subtitle>
            <v-list-item-subtitle 
             class="title-text-info">
              <span> {{ $currency.format(inc.amount) }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
     
    </div>
  </div>
</template>

<script>

import adminScheduleMaintenanceService from '@/services/admin/maintenancePayments';
import adminReservationService from '@/services/admin/reservations';
import adminMonthlyService from '@/services/admin/monthlyPayments';

export default {
  props: {
   
  },
    data() {
    return {     
      reservations: [],     
      income: {},
      monthly: [],
      dates: [this.$dayjs().subtract(200, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')],
    };
  },
   created() {   
    this.fetchReservations(this.dates);
    this.fetch();
    this.fetchMonthly();
  },
  computed: {
    imageDefaul() {
      return require('@/assets/images/avatar_dz.jpg');
    },
   
  },
  methods: {   
    fetch() {
      adminScheduleMaintenanceService
      .searchPayment('paid', null, null, this.dates[0], this.dates[1])
      .then((response) => {
        this.income = response.data;
        console.log(this.dates[0]);
        console.log(this.income);
      });
    },
    fetchMonthly() {
      adminMonthlyService.search(null, 'paid', this.dates[0], this.dates[1])
      .then((response) => {
        this.monthly = response.data;
      });
    },
    fetchReservations() {
      adminReservationService.search(null, null, 'paid', this.dates[0], this.dates[1])
      .then((response) => {
        this.reservations = response.data;
      });
    }   
  },
};
</script>