<template>
  <div>
    <div v-for="(debtors, i) in debtors" :key="i">
      <v-card style="margin-bottom: 15px">
        <v-list-item three-line>
          <v-list-item-avatar>
            <img alt="" :src="debtors.user.imageUrl || userImageDefaul" /></v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              class="title-text-info"
              v-html="debtors.user.username"
            ></v-list-item-title>
            <v-list-item-subtitle> Monto de la deuda </v-list-item-subtitle>
            <v-list-item-subtitle 
             class="title-text-info" :style="{ color: days[i]>45 ? '#ff0000' : '#498d06'}"
            >
              <span v-if="debtors.user.statusDebt != 'moroso'" > {{ $currency.format(debtors.debtAmount) }}</span>
              <span v-else style="color:#ff0000">{{ debtors.user.statusDebt }} </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </div>
  </div>
</template>


<script>
import userMaintenanceService from '@/services/users/maintenancePayments';

export default {
  data() {
    return {
      debtors: [],
    }
  },
  mounted () {
    this.fetch();
  },
  computed: {
    userImageDefaul() {
      return require('@/assets/images/avatar_dz.jpg');
    },
    days() {
      return this.debtors.map(function(item) {
          return datediff(parseDate(item.paymentDueDate), new Date());
      });
    }
  },
  methods: {
    fetch() {
      userMaintenanceService.fetchDebtors().then((response) => {
        this.debtors = response.data;
      });
    },    
  },
};

function parseDate(str) {
      return new Date(str);
}

function datediff(first, second) {
        return Math.round((second-first)/(1000*60*60*24));
 }

</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>