<template>
  <div>
    <v-menu
      ref="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateRangeText"
          label="Elige un rango de fecha"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="dates"
        range
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
  export default {
    props: {
    initialRange: {
      type: Array,
      default: ()  => {
        const start = new Date();
        const end = new Date();
         start.setDate( start.getDate() - 30 );
        return [start.toISOString().substr(0, 10), end.toISOString().substr(0, 10)];
        },
    },
  },
    data() {
      return {
        dates: this.initialRange,
      }
    },
    computed: {
      dateRangeText () {
        return this.dates.join('  a  ')
      },
    },
    watch: {
      dates(newValue) {
        this.$emit('change', newValue);
        
      }
    },
  }
</script>