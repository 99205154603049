<template>
  <div style="margin: -10px -20px -10px -20px">
    <v-list>
      <template v-for="(item, index) in items">
        <v-subheader :key="index" v-text="formatDate(index)"></v-subheader>

        <template v-for="(note, indexNote) in item">
          <v-list-item :key="note.createdAt + indexNote" @click="readed(note.id, note.readed, note.recipientId)">
            <v-list-item-avatar> 
              <v-icon v-if="!note.recipientId" large color="secundary"> mdi-bell-circle </v-icon>
              <v-icon v-else large :color="note.readed ? 'secundary' : 'primary'"> mdi-bell-circle </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="note.title"></v-list-item-title>
              <small> {{ note.relativeTime }}</small>
              <small v-html="note.description"></small>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="note.createdAt" inset />
        </template>
      </template>
    </v-list>
  </div>
</template>

<script>
import notificationService from '@/services/notifications';

export default {
  data() {
    return {
      notifications: [],
    };
  },
  created() {
    this.getNotifications();
  },
  computed: {
    imageDefaul() {
      return require('@/assets/images/avatar_dz.jpg');
    },
    items() {
      const result = {};
      this.notifications.forEach((item) => {
        if (!item.readed && item.recipientId) {
          this.$store.commit('messages');
        }
        const date = item.createdAt.substr(0, 10);
        if (Object.prototype.hasOwnProperty.call(result, date)) {
          result[date].push({
            id: item.id,
            createdAt: item.createdAt,
            relativeTime: this.$dayjs().to(this.$dayjs(item.createdAt)),
            readed: item.readed,
            recipientId: item.recipientId,
            title: item.title,
            description: item.description.replace(/\n/g, '<br/>'),
          });
        } else {
          result[date] = [
            {
              id: item.id,
              readed: item.readed,
              recipientId: item.recipientId,
              createdAt: item.createdAt,
              relativeTime: this.$dayjs().to(this.$dayjs(item.createdAt)),
              title: item.title,
              description: item.description,
            },
          ];
        }
      });
      return result;
    },
  },
  methods: {
    formatDate(date) {
      return this.$dayjs(date).format('ll');
    },
    readed(id, status, recipientId) {
      if (!status && id && recipientId) {
        notificationService.toReaded(id).then(() => {
          this.getNotifications();
        });
      }
    },
    getNotifications() {
      notificationService.fetch().then((response) => {
        this.$store.commit('resetMessages');
        this.notifications = response.data;
      });
    },
  },
};
</script>