<template>
  <v-app>
    <div class="padding-card-c grey">
      <v-card>
        <div class="padding-card-c">
          <p class="title-user-scr-b">Publicar nuevo boletín</p>
          <NewsletterForm @oncreate="getNews" />
        </div>
      </v-card>
      <p class="title-user-scr-b" style="padding-top: 50px"> Últimos boletines publicados </p>
      <v-col v-for="(item, i) in shownCards" :key="i" cols="12">
        <v-card color="white">
          <div class="d-flex flex-no-wrap justify-space-between">
            <v-list-item>
              <v-list-item-avatar>
                <img alt="" :src="item.imageUrl" />
              </v-list-item-avatar>

              <v-list-item-content style="margin-left: 15px">
                <v-list-item-title class="title-text-info">{{
                  item.title.split(' ').slice(0, 10).join(' ') + '...'
                }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  item.description.split(' ').slice(0, 11).join(' ') + '...'
                }}</v-list-item-subtitle>
                <span style="font-size: 12px"> {{ $dayjs(item.createdAt).format('lll') }} </span>
                <span v-if="item.deleted" style="font-size: 12px; color:#f00">Deleted</span>
              </v-list-item-content>

              <v-list-item-action>
                <ModalNewsletter :post="item" />
                <div style="padding-top: 10px">
                  <v-btn v-if="!item.deleted" color="primary" small fab dark @click="switchDeleted(item.id)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn v-else color="primary" small fab dark @click="switchDeleted(item.id)">
                    <v-icon>mdi-undo-variant</v-icon>
                  </v-btn>
                </div>
              </v-list-item-action>
            </v-list-item>
          </div>
        </v-card>
      </v-col>
       <div class="text-center">
      <v-pagination v-model="page" :length="length"></v-pagination>
    </div>
    </div>
  </v-app>
</template>

<script>
import adminNewService from '@/services/admin/news';

import NewsletterForm from '@/components/NewsletterForm.vue';
import ModalNewsletter from '@/components/ModalNewsletter.vue';

export default {
  props: {
    request: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    NewsletterForm,
    ModalNewsletter,
  },
  created() {
    this.getNews();   
  },
  methods: {
    getNews() {
      adminNewService.fetch().then((response) => {
        this.news = response.data;
        console.log(this.news);
      });
    },
    switchDeleted(id) {
      adminNewService.switchdel(id).then((response) => {
        this.getNews();
        console.log(response.data);
      });
    }
  },
  data() {
    return {
      page:1,
      news: [],
    };
  },

computed: {
    length() {
      return Math.ceil(this.news.length / 5);
    },

    shownCards() {
      const { page, news } = this;
      const number = 5; // Numero de elementos a mostrar por página
      return news.slice((page - 1) * number, page * number);
    },
  },

};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>