<template>
  <v-card>
    <v-img :src="booking.imageUrl"></v-img>
    <v-card-title>{{ booking.title }}</v-card-title>
    <v-card-text>
    <div class="my-4 text-subtitle-1"> {{ booking.subtitle  }} </div>

    <p>{{ booking.description }}</p>
    <v-list v-if="!booking.noPayment" class="transparent">
      <v-list-item>
        <v-list-item-title>Deposito</v-list-item-title>
        <!-- <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon> -->
        <v-list-item-subtitle class="text-right">
          {{ $currency.format(deposit) }}
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>Costo</v-list-item-title>
        <v-list-item-subtitle class="text-right">
          {{ $currency.format(booking.costAmount) }}
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>Total</v-list-item-title>
        <v-list-item-subtitle class="text-right">
          {{ $currency.format(total) }}
        </v-list-item-subtitle>
      </v-list-item>
    </v-list>
    <v-btn
      block
      color="primary"
      large
      rounded
      link
      :to="{ name: 'bookingdateselect' }"
      style="margin-top: 25px"
      >Siguiente</v-btn
    >
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      booking: 'room/currentRoom',
    }),
    deposit() {
      return this.booking.deposit
      ? this.booking.depositAmount
      : 0;
    },
    total() {
      return this.deposit + this.booking.costAmount;
    }
  },
};
</script>
