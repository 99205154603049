<template>
  <div>
    <div style="display: flex">
      <v-subheader class="title-user-scr-b">Pagos mensuales</v-subheader>

      <v-spacer></v-spacer>
    </div>
    <v-simple-table dense>
      <thead>
        <tr>
          <th id="lote-1" class="text-left title-table-col">#Id</th>
          <th id="lote-1" class="text-left title-table-col">Concepto</th>
          <th id="lote-2" class="text-left title-table-col">Importe</th>
          <th id="lote-3" class="text-left title-table-col">Fecha de vencimiento</th>
          <th id="lote-4" class="text-left title-table-col">Recargo</th>
          <th id="lote-5" class="text-left title-table-col">Condonar recargo</th>
          <th id="lote-6" class="text-left title-table-col">Estatus</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(textField, index) in paymentsmainten" :key="index" class="text-fields-row">
          <td style="width: 30%">{{ textField.id }} {{ textField.customer.username }} {{ textField.customer.usernameLogin }}</td>
          <td style="width: 20%">
            <span>{{ textField.concept }}</span>
          </td>
          <td style="width: 15%">
            <span>{{ $currency.format(textField.amount) }}</span>
          </td>
          <td style="width: 15%">
            {{ textField.paymentDueDate }}
          </td>
          <td v-if="textField.id" style="width: 15%">
            {{ $currency.format(textField.surcharges) }}
          </td>
          <td v-else>-</td>
          <td v-if="textField.id" style="width: 10%; padding-left: 35px">
            <v-checkbox
              :disabled="textField.surcharges == 0 || textField.status === 'paid'"
              v-model="textField.forgiveSurcharges"
              @click="alertcondone(textField)"
            />
          </td>
          <td v-else>-</td>
          <td v-if="textField.id" style="width: 15%">
            {{ textField.status }}
          </td>
          <td v-else>No pagado</td>

          <td v-if="textField.id" style="width: 5%">
            <v-btn
              :disabled="['paid', 'canceled'].includes(textField.status)"
              @click="alertregisterpayment(textField)"
              tile
              color="success"
            >
              <v-icon> mdi-cash-check </v-icon>
            </v-btn>
          </td>
          <td v-else>-</td>

          <td v-if="textField.id" style="width: 5%"> </td>
          <td v-else>-</td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import adminScheduleMaintenanceService from '@/services/admin/maintenancePayments';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);
dayjs.locale('es');

export default {
  props: {
    customerId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      paymentsmainten: [],
      loading: false,
      validForGenerateModelPayment: true,
      dayjs,
      textFields: [],
      items: ['pending', 'paid', 'canceled'],
      initialConcept: '',
      initialPayment: '',
      initialDate: dayjs(Date.now()).format('YYYY-MM-DD'),
      initialPayments: [],
      optionalPayments: [],
      modelPymentData: {
        startYear: dayjs(Date.now()).format('YYYY'),
        startMonth: dayjs(Date.now()).format('MM'),
        paymentDueDay: '05',
        amount: '0',
        numberMonthlyPayments: '0',
      },
    };
  },
  mounted() {
    this.fetch();
  },
  computed: {
    existPayments() {
      return this.optionalPayments.some((item) => Object.prototype.hasOwnProperty.call(item, 'id'));
    },
  },
  methods: {
    actionsPay(item, action) {
      this.loading = true;
      adminScheduleMaintenanceService[action](item.id)
        .then(() => {
          this.fetch();
          let message = '';
          if (action === 'payByIdPayment') {
            message = 'Se ha registrado el pago correctamente';
          }
          if (action === 'cancelSurchargesByIdPayment') {
            message = 'Se condonaron los recargos del mes seleccionado';
          }
          this.$swal('Operación realizada', message, 'success');
          this.loading = false;
        })
        .catch((error) => {
          if (error.response && error.response.status >= 400) {
            this.$swal('Error al actualizar configuraciones de recargo', error.response.data.message, 'warning');
          }
        });
    },

    fetch() {
      adminScheduleMaintenanceService.searchPayment('all', this.customerId).then((response) => {
        this.paymentsmainten = response.data;
      });
    },

    alertcondone(textField) {
      // Use sweetalert2
      this.$swal({
        title: '¿Estás seguro?',
        text: 'Recuerda que al condonar el pago de recargo este será $0 y no habrá forma de reajustarlo',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, ¡condonar pago!',
        cancelButtonText: 'No, ¡cancelar!',
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.actionsPay(textField, 'cancelSurchargesByIdPayment');
        } else {
          this.$swal('Operación cancelada', 'No se condonaron los recargos del mes seleccionado', 'error');
        }
      });
    },

    alertregisterpayment(textField) {
      // Use sweetalert2
      this.$swal({
        title: '¿Estás seguro?',
        text: 'Recuerda que al registrar un pago no habrá forma de cambiar el estatus nuevamente',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, ¡registrar pago!',
        cancelButtonText: 'No, ¡cancelar!',
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.actionsPay(textField, 'payByIdPayment');
        } else {
          this.$swal('Operación cancelada', 'No se registró el pago del usuario', 'error');
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>
