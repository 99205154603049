<template>
<div>
  <v-form ref="formNotifications" v-model="validForm" lazy-validation>
    <p class="title-user-scr-b">Enviar notificación push</p>
    <v-row>
      <v-col class="d-flex" cols="12" sm="6">
        <v-autocomplete
          :rules="rules.userRequired"
          multiple
          v-model="userIds"
          :item-text="username"
          item-value="id"
          label="Usuario"
          :items="users"
        ></v-autocomplete>
      </v-col>
      <v-col class="d-flex" cols="12" sm="1"> </v-col>
      <v-checkbox v-model="checkbox" :label="`Enviar a todos`"></v-checkbox>
    </v-row>
    <v-text-field :rules="[rules.required]" v-model="title" label="Título de la notificación"></v-text-field>
    <v-textarea
      :rules="[rules.required]"
      v-model="message"
      label="Texto de la notificación"
      outlined
      name="input-7-4"
    ></v-textarea>

    <v-btn @click="sendToUsers" depressed color="#00aff2" dark> Enviar </v-btn>
  </v-form>
</div>
</template>

<script>
import adminNotificationService from '@/services/admin/notifications';
import adminUserService from '@/services/admin/users';

export default {
  data() {
    return {
      validFormSuscribe: true,
      checkbox: false,
      title: null,
      message: null,
      validForm: true,
      users: [],
      userIds: null,
      rules: {
        required: (value) => !!value || 'Required.',
        userRequired: [(v) => !!v || this.checkbox || 'User is required'],
      },
    };
  },
  created() {
    this.getUsers();
  },
  methods: {
    getUsers() {
      adminUserService.getUsers().then((response) => {
        this.users = response.data;
      });
    },
    username(user) {
      return `${user.username} [${user.email}]`;
    },
    sendToUsers() {
      if (this.$refs.formNotifications.validate()) {
        let actionDefault = 'sendToUsers';
        const data = {
          title: this.title,
          message: this.message,
        };
        if (!this.checkbox) {
          actionDefault = 'sendToSpecificUsers';
          data.recipientIds = this.userIds;
        }
        adminNotificationService[actionDefault](data)
          .then((response) => {
            if (actionDefault === 'sendToSpecificUsers' && !response.data.success) {
              this.$swal('Notificación', 'Algunos usuarios no recibieron la notificación', 'error');
              return;
            }
            this.$refs.formNotifications.reset();
            this.$emit('on-save', response.data);
            this.$swal(
              'Notificación enviada',
              'La notificación ha sido enviada correctamente.',
              'success'
            );
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              this.$swal('Error al enviar notificación', error.response.data.message, 'warning');
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>