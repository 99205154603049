<template>
<div>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :rules="rules"
        :value="time"
        :label="label"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="menu"
      v-model="time"
      :allowed-hours="allowedHours"
      :allowed-minutes="allowedMinutes"
      class="mt-4"
      format="24hr"
      scrollable
      :min="min"
      :max="max"
      @click:hour="closePicker"
    ></v-time-picker>
  </v-menu>
</div>
</template>
<script>
export default {
  props: {
    rules: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Hora',
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
    allowedHours: {
      type: Function,
      default: null,
    },
    allowedMinutes: {
      type: Function,
      default: null,
    },
    initialHour: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    const initialHour = this.initialHour ? this.initialHour.toString() + ':00' : '00:00';
    return {
      time: initialHour,
      menu: false,
    };
  },
  methods: {
    closePicker(v) {
      v = v < 10 ? '0' + v : v;
      this.time = v + ':00';
      this.$emit('change', v);
      this.menu = false;
    },
  },
};
</script>