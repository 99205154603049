<template>
  <div>
    <v-col v-for="(events, index) in shownCards" :key="index" cols="12" md="12" style="margin-bottom: 15px">
      <v-card color="white">
        <v-row>
          <v-col cols="4" align="center">
            <span class="title-user-scr-b" v-html="getMonth(events.reservationDate)"></span><br />
            <span class="title-modal" v-html="getDay(events.reservationDate)"></span> <br />
            <span class="room-name-text"> {{ events.room.title }} </span>
          </v-col>

          <v-divider vertical></v-divider>

          <v-col cols="8">
            <span class="title-card-event">{{ events.title.split(' ').slice(0, 6).join(' ') + '...' }}</span
            ><br />
            <span> {{ getHour(events.reservationHour) }} </span>
            a <span> {{ getHour(events.reservationHour + events.lapse) }} </span>
            <v-row style="display: flex">
              <v-col>
                <v-avatar size="25">
                  <img alt="" :src="events.customer.avatar || userImageDefault" />
                </v-avatar>

                <span> {{ events.customer.name.split(' ').slice(0, 3).join(' ') + '...' }} </span>
              </v-col>
            </v-row>
            <span class="title-card-event-payment" v-if="events.status!=='paid' && !isAdmin && !events.noPayment">* sin pago, cancelación automática 2 días antes</span>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <div class="text-center">
      <v-pagination v-model="page" :length="length"></v-pagination>
    </div>
  </div>
</template>



<script>
export default {
  props: {
    request: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  data() {
    return {
      page: 1,
    };
  },
  
  methods: {
    getHour(hour) {
      return hour + ':00';
    },
    getMonth(date) {
      const meses = ['En.', 'Febr.', 'Mzo.', 'Abr.', 'My.', 'Jun.', 'Jul.', 'Agt.', 'Sept.', 'Oct.', 'Nov.', 'Dic.'];
      return meses[date.split('-')[1] - 1];
    },
    getDay(date) {
      return date.split('-')[2];
    },
    accept() {
      this.$emit('accept', this.request.id);
    },
    paid() {
      this.$emit('paid', this.request.id);
    },
    cancel() {
      this.$emit('cancel', this.request.id);
    },
  },

  computed: {
    userImageDefault() {
      return require('@/assets/images/avatar_dz.jpg');
    },
    length() {
      return Math.ceil(this.request.length / 3);
    },
    isAdmin() {
      return this.$store.getters['session/user'].role === 'admin';
    },

    shownCards() {
      const { page, request } = this;
      const number = 3; // Numero de elementos a mostrar por página
      return request.slice((page - 1) * number, page * number);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>