<template>
  <div class="grey">
    <v-row class="padding-row-c">
      <div style="padding-left: 25px">
        <ModalBooking @update="update" />
      </div>
    </v-row>
    <v-row class="padding-row-c">
      <v-col cols="12" sm="12">
        <v-card class="padding-card">
          <Calendar v-if="!loading" :reservations="reservations" :blocked-dates="blockedDates" />
        </v-card>
      </v-col>
    </v-row>
    <v-row class="padding-card-d">
      <v-col cols="12" sm="6">
        <v-card class="padding-card">
          <span class="title-user-scr-b">Solicitudes</span>
          <CardEventRequest :request="requests" @accept="acceptToPay" @cancel="cancel" @update="update"/>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="padding-card">
          <span class="title-user-scr-b">Esperando pago del evento</span>
          <CardEventRequest :request="upcomingEventsToPay" @update="update" @deposit="depositConfirm" @paid="confirm" @cancel="cancel" />
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="padding-card">
          <span class="title-user-scr-b">Eventos hoy</span>
          <CardEvent :request="todayEvents" />
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="padding-card">
          <span class="title-user-scr-b">Próximos eventos</span>
          <CardEventRequest :request="upcomingEvents"  @cancel="cancel" @update="update" /> 
        </v-card>
      </v-col>
    </v-row>
    <v-row class="padding-card-d">
      <v-col cols="12" sm="6">
        <v-card class="padding-card">
          <span class="title-user-scr-b">Eventos realizados</span>
          <CardEvent :request="eventsHeld" /> 
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="padding-card">
          <span class="title-user-scr-b">Eventos cancelados</span>
          <CardEvent :request="requestsCancel" />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import adminReservationsService from '@/services/admin/reservations';

import Calendar from '@/components/Calendar.vue';
import CardEventRequest from '@/components/CardEventRequest.vue';
import CardEvent from '@/components/CardEvent.vue';

import ModalBooking from '@/components/ModalBooking.vue';
export default {
  components: {
    Calendar,
    CardEventRequest,
    CardEvent,
    ModalBooking,
  },
  data() {
    return {
      reservations: [],
      blockedDates: [],
      reservationsRequest: [],
      loading: false
    };
  },
  created() {
    this.update();
   
  },
  computed: {
    requests() {
      return this.reservationsRequest.filter((item) => item.status === 'pending');
    },
    requestsCancel() {
      return this.reservationsRequest.filter((item) => item.status === 'canceled');
    },
    upcomingEventsToPay() {
      return this.reservationsRequest.filter((item) => {
        return item.status === 'accepted' && item.statusPayment === 'pending';
      });     
    },
    eventsHeld() {
      return this.reservationsRequest.filter((item) => {
        const today = new Date(this.$getDate()).getTime();
        const date = new Date(item.reservationDate).getTime();
        return item.status !== 'canceled' && date < today;
      });
    },
    upcomingEvents() {
      return this.reservationsRequest.filter((item) => {        
        const today = new Date(this.$getDate()).getTime();
        const date = new Date(item.reservationDate).getTime();
        return item.status !== 'canceled' && ((item.paymentValidated && item.statusPayment === 'paid') || (item.noPayment && item.status === 'accepted')) && date > today;
      });
    }, 
    todayEvents() {
      return this.reservationsRequest.filter((item) => {
        const today = this.$formatShowDate(new Date());
        return item.status !== 'canceled' && today.includes(item.reservationDate);
      });
    }
    
  },
  methods: {
    update() {
      this.loading = true;
      this.getCalendar();
      this.getReservationsRequest();
      this.loading = false;
      console.log('Update ');
    },
    getCalendar() {
      adminReservationsService
        .fetchCalendar()
        .then((response) => {
          this.reservations = response.data.reservations;
          this.blockedDates = response.data.blockedDates;         
        })
        .catch((error) => {
          if (error.response && [400, 409].includes(error.response.status)) {
            this.$swal('Error al actualizar reservación', error.response.data.message, 'warning');
          }
        });
    },
    getReservationsRequest() {
      adminReservationsService
        .search()
        .then((response) => {
          this.reservationsRequest = response.data;
          
        })
        .catch((error) => {
          if (error.response && [400, 409].includes(error.response.status)) {
            this.$swal('Error al obtener reservación', error.response.data.message, 'warning');
          }
        });
    },
    confirm(id) {
      adminReservationsService
        .reservationConfirm(id)
        .then(() => {
          this.update();
        })
        .catch((error) => {
          if (error.response && [400, 409].includes(error.response.status)) {
            this.$swal('Error al confirmar reservación', error.response.data.message, 'warning');
          }
        });
    },
    acceptToPay(id) {
      const [request] = this.requests.filter((item) => item.id === id);
      const reqIdToCancel = [];
      const reqTitlesToCancel = [];
      this.requests.forEach((element) => {
        if (element.reservationDate === request.reservationDate && element.id !== id) {
          const hourStartA = request.reservationHour;
          const hourEndA = hourStartA + request.lapse;

          const hourStartB = element.reservationHour;
          const hourEndB = hourStartB + element.lapse;
          const lapse = `<strong>${element.title}</strong> de ${hourStartB}:00 hrs a ${hourEndB}:00 hrs`;

          if (hourStartA <= hourStartB && hourStartB <= hourEndA) {
            reqIdToCancel.push(adminReservationsService.cancel(element.id));
            reqTitlesToCancel.push(lapse);
          } else {
            if (hourStartA <= hourEndB && hourEndB <= hourEndA) {
              reqIdToCancel.push(adminReservationsService.cancel(element.id));
              reqTitlesToCancel.push(lapse);
            } else {
              if (hourStartB <= hourStartA && hourEndA <= hourEndB) {
                reqIdToCancel.push(adminReservationsService.cancel(element.id));
                reqTitlesToCancel.push(lapse);
              }
            }
          }
        }
      });
      if (reqIdToCancel.length) {
        const message = `Las siguientes solicitudes serán rechazadas debido a que
        presentan un traslape de horario: <br>
        ${reqTitlesToCancel.join('<br>')}
        `;
        this.$swal({
          title: 'Conflicto de reservaciones',
          html: message,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sí',
          cancelButtonText: 'No',
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            adminReservationsService
              .acceptToPay(id)
              .then(async () => {
                this.$swal.fire({
                  position: 'top-end',
                  icon: 'info',
                  title: 'Procediendo a rechazar reservaciones',
                  showConfirmButton: false,
                  timer: 1000,
                });
                await Promise.all(reqIdToCancel);
                this.$swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Proceso finalizado exitosamente',
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.update();
              })
              .catch((error) => {
                if (error.response && [400, 409].includes(error.response.status)) {
                  this.$swal('Error al aceptar reservación', error.response.data.message, 'warning');
                }
              });
          } else {
            this.$swal('Operación cancelada', '', 'error');
          }
        });
      } else {
        adminReservationsService
          .acceptToPay(id)
          .then(async () => {
            this.update();
          })
          .catch((error) => {
            if (error.response && [400, 409].includes(error.response.status)) {
              this.$swal('Error al aceptar reservación', error.response.data.message, 'warning');
            }
          });
      }
    },
    depositConfirm(id) {
      adminReservationsService
        .depositConfirm(id)
        .then(() => {
          this.update();
        })
        .catch((error) => {
          if (error.response && [400, 409].includes(error.response.status)) {
            this.$swal('Error al confirmar deposito de reservación', error.response.data.message, 'warning');
          }
        });
    },
    cancel(id) {
      adminReservationsService
        .cancel(id)
        .then(() => {
          this.update();
        })
        .catch((error) => {
          if (error.response && [400, 409].includes(error.response.status)) {
            this.$swal('Error al cancelar reservación', error.response.data.message, 'warning');
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>