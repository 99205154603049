import { apiClient } from '../index';

/**
 * [Crea una noticia]
 * @return {[type]}          [description]
 */
function fetch() {
  return apiClient.get('/v1/notifications');
}

/**
 * [Crea una noticia]
 * @return {[type]}          [description]
 */
function createSuscribe() {
  const data = {
    endpoint: 'string',
    expirationTime: 'string',
    keys: {
      p256dh: 'string',
      auth: 'string',
    },
  };
  return apiClient.post('/v1/notifications/subscribe', data);
}

/**
 * [Crea una noticia]
 * @param {string} title          [description]
 * @param {string} message        [description]
 * @param {array} recipientIds        [description]
 * @return {[type]}          [description]
 */
function sendToSpecificUsers(data) {
  return apiClient.post('/v1/notifications/notify', data);
}

/**
 * [Crea una noticia]
 * @param {string} title          [description]
 * @param {string} message        [description]
 * @return {[type]}          [description]
 */
function sendToUsers(data) {
  return apiClient.post('/v1/notifications/broadcast', data);
}

export default {
  fetch,
  createSuscribe,
  sendToSpecificUsers,
  sendToUsers,
};
