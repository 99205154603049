import { apiClient } from '../index';

/**
 * [Actualiza un solón de fiestas]
 * @return {[type]}          [description]
 */
function update(id, formData) {
  return apiClient.put('/v1/party-rooms/' + id, formData);
}

/**
 * [Crea un salón de fiestas]
 * @return {[type]}          [description]
 */
 function create(formData) {
  return apiClient.post('/v1/party-rooms/', formData);
}

/**
 * [Obtiene los salones de fiestas]
 * @return {[type]}          [description]
 */
function fetch() {
  return apiClient.get('/v1/party-rooms');
}

export default { update, create, fetch };
