<template>
  <v-container style="padding-bottom: 50px; padding-right: 50px" class="grey">
    <v-row class="padding-row-b">
      <v-col cols="4">
        <v-card class="card-currency">
          <span>Monto de ingresos del periodo</span>
          <p class="title-modal">{{ $currency.format(total) }}</p>
        </v-card>
      </v-col>
      <v-col style="margin-top: 30px" cols="4" md="4">
        <DateSelect :initialRage="dates" @change="fetchPayments" />
      </v-col>
    </v-row>

    <v-row class="padding-row-b">
      <v-col cols="4">
        <v-card class="card-currency">
          <span>Monto de ingresos del periodo</span>
          <p class="title-modal">{{ $currency.format(totalIncome) }}</p>
          <v-autocomplete
            item-text="concept"
            item-value="id"
            label="Plan"
            v-model="scheduleSelected"
            :items="schedules"
            @change="fetch"
          />
        </v-card>
      </v-col>
      <v-col cols="8">
        <v-container>
          <v-row dense>
            <v-col v-for="(income, i) in showMaintenanceIncome" :key="i" cols="12">
              <v-card color="white">
                <div class="d-flex flex-no-wrap justify-space-between">
                  <v-list-item>
                    <v-list-item-avatar>
                      <img alt="" :src="getUserAvatar(income.customerId) || imageDefaul" />
                    </v-list-item-avatar>

                    <v-list-item-content style="margin-left: 15px">
                      <v-list-item-title class="title-text-info" v-html="income.concept"></v-list-item-title>
                      <v-list-item-subtitle>
                        {{ getUserInfo(income.customerId) }} - {{ income.paymentMethod }}
                      </v-list-item-subtitle>
                      <span style="font-size: 12px" v-html="$dayjs(income.paymentDate).format('lll')"></span>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text class="title-text-info">{{
                        $currency.format(income.amount)
                      }}</v-list-item-action-text>
                      <ModalIncome :info="income" />
                    </v-list-item-action>
                  </v-list-item>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <div class="text-center">
            <v-pagination v-model="pageMaintenance" :length="lengthMaintenanceIncome"></v-pagination>
          </div>
        </v-container>
      </v-col>
    </v-row>
    <v-row class="padding-row-b">
      <v-col cols="4">
        <v-card class="card-currency">
          <span>Monto de ingresos del periodo</span>
          <p class="title-modal">{{ $currency.format(totalMonthly) }}</p>
        </v-card>
      </v-col>
      <v-col cols="8">
        <v-container>
          <v-row dense>
            <v-col v-for="(income, i) in showMontlyIncome" :key="i" cols="12">
              <v-card color="white">
                <div class="d-flex flex-no-wrap justify-space-between">
                  <v-list-item>
                    <v-list-item-avatar>
                      <img alt="" :src="getUserAvatar(income.customerId) || imageDefaul" />
                    </v-list-item-avatar>

                    <v-list-item-content style="margin-left: 15px">
                      <v-list-item-title class="title-text-info" v-html="income.concept"></v-list-item-title>
                      <v-list-item-subtitle>
                        {{ getUserInfo(income.customerId) }} - {{ income.paymentMethod }}
                      </v-list-item-subtitle>
                      <span style="font-size: 12px" v-html="$dayjs(income.paymentDate).format('lll')"></span>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text class="title-text-info">{{
                        $currency.format(income.amount)
                      }}</v-list-item-action-text>
                      <ModalIncome :info="income" />
                    </v-list-item-action>
                  </v-list-item>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <div class="text-center">
            <v-pagination v-model="pageMontly" :length="lengthMontlyIncome"></v-pagination>
          </div>
        </v-container>
      </v-col>
    </v-row>
    <v-row style="padding: 50px 0px 0px 50px">
      <v-col cols="4">
        <v-card style="padding: 30px 30px 10px 30px; border-radius: 25px">
          <span>Reservaciones: Monto de ingresos del periodo</span>
          <p style="font-size: 26px; font-weight: 600">{{ $currency.format(totalReservation) }}</p>
        </v-card>
      </v-col>
      <v-col cols="8">
        <v-container>
          <v-row dense>
            <v-col v-for="(room, i) in showBookingIncome" :key="i" cols="12">
              <v-card color="white">
                <div class="d-flex flex-no-wrap justify-space-between">
                  <v-list-item>
                    <v-list-item-avatar>
                      <img alt="" :src="getUserAvatar(income.customerId) || imageDefaul" />
                    </v-list-item-avatar>

                    <v-list-item-content style="margin-left: 15px">
                      <v-list-item-title style="font-size: 16px; font-weight: 600">{{
                        room.title.split(' ').slice(0, 6).join(' ') + '...'
                      }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ getUserInfo(room.customerId) }} <br />
                        {{ room.room.title }}
                      </v-list-item-subtitle>
                      <span style="font-size: 12px" v-html="$dayjs(room.updatedAt).format('lll')"></span>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text style="font-size: 16px; font-weight: 600">{{
                        $currency.format(
                          room.room.costAmount + (room.room.deposit && room.depositPaid ? room.room.depositAmount : 0)
                        )
                      }}</v-list-item-action-text>
                      <!-- <ModalIncome :info="room" /> -->
                    </v-list-item-action>
                  </v-list-item>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <div class="text-center">
            <v-pagination v-model="pageBooking" :length="lengthBookingIncome"></v-pagination>
          </div>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import adminUserService from '@/services/admin/users';
import adminScheduleMaintenanceService from '@/services/admin/maintenancePayments';
import adminMonthlyService from '@/services/admin/monthlyPayments';
import adminReservationService from '@/services/admin/reservations';

import ModalIncome from '@/components/ModalIncome.vue';
import DateSelect from '@/components/DateSelect.vue';
export default {
  props: {
    request: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ModalIncome,
    DateSelect,
  },
  data() {
    return {
      pageMaintenance: 1,
      pageMontly: 1,
      pageBooking: 1,
      scheduleSelected: null,
      income: [],
      schedules: [],
      monthly: [],
      reservations: [],
      dates: [this.$dayjs().subtract(30, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')],
    };
  },
  created() {
    this.fetchSchedulePayment(this.dates);
    this.fetchReservations(this.dates);
    this.getUsers();
  },
  computed: {
    total() {
      return this.totalIncome + this.totalMonthly + this.totalReservation;
    },
    totalIncome() {
      if (!this.income.length) return 0;
      const reducer = (a, b) => a + b.amount;
      return this.income.reduce(reducer, 0);
    },
    totalMonthly() {
      if (!this.monthly.length) return 0;
      const reducer = (a, b) => a + b.amount;
      return this.monthly.reduce(reducer, 0);
    },
    totalReservation() {
      if (!this.reservations.length) return 0;
      const reducer = (a, b) => {
        let result = a + b.room.costAmount;
        if (b.room.deposit && b.depositPaid) {
          result += b.room.depositAmount;
        }
        return result;
      };
      return this.reservations.reduce(reducer, 0);
    },
    imageDefaul() {
      return require('@/assets/images/avatar_dz.jpg');
    },
    lengthMaintenanceIncome() {
      return Math.ceil(this.income.length / 5);
    },
    lengthMontlyIncome() {
      return Math.ceil(this.monthly.length / 5);
    },
    lengthBookingIncome() {
      return Math.ceil(this.reservations.length / 5);
    },

    showMaintenanceIncome() {
      const { pageMaintenance, income } = this;
      const number = 5; // Numero de elementos a mostrar por página
      return income.slice((pageMaintenance - 1) * number, pageMaintenance * number);
    },
    showMontlyIncome() {
      const { pageMontly, monthly } = this;
      const number = 5; // Numero de elementos a mostrar por página
      return monthly.slice((pageMontly - 1) * number, pageMontly * number);
    },
    showBookingIncome() {
      const { pageBooking, reservations } = this;
      const number = 5; // Numero de elementos a mostrar por página
      return reservations.slice((pageBooking - 1) * number, pageBooking * number);
    },
  },
  methods: {
    fetchPayments(dates) {
      if (dates.length === 2) {
        this.dates = dates;
        // const startDate = dates[0];
        // const endDate = dates[1];
        this.fetchSchedulePayment();
        this.fetchReservations();
      }
    },
    fetchSchedulePayment() {
      const year = new Date(this.dates[1]).getFullYear();
      adminScheduleMaintenanceService.fetch().then((response) => {
        this.schedules = response.data;
        const search = this.schedules.filter((item) => item.paymentYear.toString() === year.toString());
        this.scheduleSelected = search.length ? search[0].id : null;
        this.fetch();
        this.fetchMonthly();
      });
    },
    getUserInfo(userId) {
      if (!this.users) {
        return '';
      }
      const user = this.users.filter((item) => item.id === userId)[0];
      return user.username;
    },
    getUserAvatar(userId) {
      if (!this.users) {
        return '';
      }
      const user = this.users.filter((item) => item.id === userId)[0];
      if (!user) return null;
      return user.imageUrl;
    },
    getUsers() {
      adminUserService.getUsers('all').then((response) => {
        this.users = response.data;
      });
    },
    username(user) {
      return `${user.username} [${user.email}]`;
    },
    fetch() {
      if (!this.scheduleSelected) return this.income;
      adminScheduleMaintenanceService
        .searchPayment('paid', null, this.scheduleSelected, this.dates[0], this.dates[1])
        .then((response) => {
          this.income = response.data;
        });
    },
    fetchMonthly() {
      adminMonthlyService.search(null, 'paid', this.dates[0], this.dates[1]).then((response) => {
        this.monthly = response.data;
      });
    },
    fetchReservations() {
      adminReservationService.search(null, null, 'paid', this.dates[0], this.dates[1]).then((response) => {
        this.reservations = response.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>