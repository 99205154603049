import { apiClient } from '../index';

/**
 * [CEncuentra las cutotas de mantenimiento según los criterios de búsqueda]
 * @return {[type]}          [description]
 */
 function fetch(paymentYear, limit = 3) {
  return apiClient.get('/v1/maintenance-payments/fees', {
    params: {
      paymentYear, limit
    }
  });
}

/**
 * [Encuentra los pagos de manteniemto según los criterios de búsqueda]
 * @return {[type]}          [description]
 */
 function searchPayment(status, customerId, maintenanceFeeId, limit = 12) {
  return apiClient.get('/v1/maintenance-payments/search', { params: {
    status, customerId, maintenanceFeeId, limit
  } });
}

/**
 * [Encuentra todos los deudores de cutotas de mantenimiento]
 * @return {[type]}          [description]
 */
 function fetchDebtors() {
  return apiClient.get('/v1/maintenance-payments/debtors');
}

export default {
  fetch,
  searchPayment,
  fetchDebtors,
}