<template>
  <div>
    <div style="display: flex; align-items: center; justify-content: center; padding: 50px 0px 50px 0px">
      <span class="title-user-scr-b">Reglamentos</span>
    </div>

    <v-btn block color="primary" large rounded target="_blank" href="../pdfs/uso_suelo.pdf" >Construcción uso de suelo</v-btn>
    <br />
  
    <v-btn block color="primary" large rounded target="_blank" href="../pdfs/casa_club.pdf">Casa Club</v-btn>
    <br />
  
    <div>{{ pdf_file }}</div>

  </div>
</template>


<script>
import serviceGeneral from '@/services/users';

export default {
 
  data() {
    return {
      documentPath:"casa_club.pdf",
      pdf_file: undefined
    }
  },
  methods: {
    getPdf() {
      serviceGeneral.getpdf().then((response) => {
        console.log(response);
        this.pdf_file = response;
      });
    }
  }  
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>