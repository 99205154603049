   <template>
  <v-row justify="end">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" small fab dark v-bind="attrs" v-on="on">
          <v-icon>mdi-tools</v-icon>
        </v-btn>

        <span class="text" style="padding-top: 10px; padding-left: 15px">
          Crear plan de mantenimiento &nbsp; &nbsp; &nbsp;
        </span>
      </template>

      <v-card>
        <div class="padding-card">
          <p class="title-modal"> <v-icon> mdi-tools </v-icon> Configuración de plan de mantenimiento </p>

         <v-form ref="formSchedulePayment" v-model="validFormSchedule" lazy-validation>
            <v-text-field :rules="[rules.required]" v-model="concept" label="Nombre del plan"></v-text-field>
            <v-text-field :rules="[rules.required]" v-model="description" label="Descripción del plan"></v-text-field>

            <v-row>
              <v-col cols="6" sm="6">
                <v-select v-model="paymentYear" :rules="[rules.required]" :items="numbersYear" label="Año"></v-select>
              </v-col>
              <v-col cols="6" sm="6">
                <v-select
                  v-model="paymentDueDay"
                  :rules="[rules.required]"
                  :items="numbersDay"
                  label="Día de vencimiento"
                ></v-select>
              </v-col>
            </v-row>
            <v-text-field
              :rules="[rules.required]"
              type="number"
              min="0"
              v-model="amount"
              label="Importe de pago"
            ></v-text-field>
          </v-form>
        </div>
        <v-card-actions>
          <v-btn @click="createSchedulePayment" depressed color="#00aff2" dark>Crear</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import adminScheduleMaintenanceService from '@/services/admin/maintenancePayments';

export default {
  data() {
    return {
      dialog: false,
      concept: '',
      description: '',
      paymentYear: 2021,
      paymentDueDay: 5,
      amount: 0,
      validFormSchedule: true,
      settings: [],
      rules: {
        required: (value) => !!value || 'Required.',
        name: [
          (v) => !!v || 'Nombre is required',
          (v) => v.length <= 60 || 'Nombre debe ser menor a 60 caracteres ',
          (v) => v.length >= 2 || 'Nombre debe ser mayor a 2 caracteres ',
        ],
        min: (v) => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => `The email and password you entered don't match`,
        email: [(v) => !!v || 'E-mail is required', (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
      },
    };
  },
  computed: {
    numbersDay() {
      return Array.from({ length: 28 }, (_, i) => i + 1);
    },
    numbersYear() {
      return Array.from({ length: 50 }, (_, i) => i + 2021);
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    createSchedulePayment() {
      const amount = this.amount * 100;
      if (!Number.isInteger(amount)) {
        this.$swal('Cobro de mantenimiento', 'El cobro de mantenimiento debe tener a lo más dos decimales.', 'warninf');
        return;
      }
      const data = {
        concept: this.concept,
        description: this.description,
        paymentYear: this.paymentYear,
        paymentDueDay: this.paymentDueDay,
        amount,
      };
      if (this.$refs.formSchedulePayment.validate()) {
        adminScheduleMaintenanceService.create(data).then(() => {
          this.$swal(
            'Plan de mantenimiento creado',
            'Se ha creado el plan de mantenimiento de forma exitosa.',
            'success'
          );
          this.$emit('exit', true);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>

