<template>
  <v-form ref="formReservation" v-model="validForm" lazy-validation>
    <span class="title-user-scr">Solicitud de reservación</span>
    <div class="title-user-scr">{{ booking.title }}</div>
    <v-text-field :rules="[rules.required]" v-model="title" label="Evento a realizar"></v-text-field>

    <v-col sm="6">
      <label for="date"
        ><DatePicker
          :min="min"
          :initialDate="min"
          label="Fecha de reservación"
          name="date"
          @change="reservationDate = $event"
      /></label>
    </v-col>
    <v-row class="d-flex align-center mb-6">
      <v-col sm="6">
        <time-picker
          label="Hora de reservación"
          :rules="[rules.required]"
          :initialHour="reservationHour"
          :max="(24-settings.reservationBlockSize).toString()"
          @change="reservationHour = $event"
        ></time-picker>
      </v-col>
      <v-col sm="6">
        <div>Finaliza: {{ calcEndHour(reservationHour, booking.lapse) }}</div>
      </v-col>
    </v-row>

    <v-textarea
      :rules="[rules.required]"
      v-model="description"
      label="Mensaje adicional"
      outlined
      name="input-7-4"
    ></v-textarea>
    <v-btn block color="primary" large rounded link @click="request" style="margin-top: 25px">Enviar solicitud</v-btn>
  </v-form>
</template>



<script>
import reservationService from '@/services/users/reservations';

import { mapGetters } from 'vuex';

import DatePicker from '@/components/DatePicker.vue';
import TimePicker from '@/components/TimePicker.vue';
export default {
  components: {
    DatePicker,
    TimePicker,
  },
  data() {
    return {
      validForm: true,
      menu1: false,
      title: '',
      description: '',
      reservationHour: '',
      reservationDate: this.$dayjs(Date.now()).add(1, 'd').format('YYYY-MM-DD'),
      min: this.$dayjs(Date.now()).add(1, 'd').format('YYYY-MM-DD'),
      rules: {
        min: (value) =>
          value >= this.$settingBlockSize || 'La duración debe ser mayor a ' + this.$settingBlockSize + ' horas',
        max: (value) => value <= 23 || 'La duración debe ser menor a 24 horas',
        required: (value) => !!value || 'Required.',
      },
    };
  },

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    ...mapGetters({
      booking: 'room/currentRoom',
      settings: 'settings',
      user: 'session/user',
    }),
  },

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },

  methods: {
    calcEndHour(start, end) {
      const hour = Number(start) + Number(end);
     if (hour === 24) {
       return '00:00 Hrs'
     } 
     return hour.toString().padStart(2, "0") + ':00 Hrs';
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    request() {
      if (this.$refs.formReservation.validate()) {
        const data = {
          title: this.title,
          description: this.description,
          reservationDate: this.reservationDate,
          customerId: this.customerId,
          reservationHour: this.reservationHour || 0,
          lapse: this.booking.lapse,
          partyRoomId: this.booking.id,
        };
        reservationService.create(data).then( () => {
          this.$router.push({ name: 'bookingthanks' });
        }).catch((error) => {
              if (error.response && [400, 409].includes(error.response.status)) {
                this.$swal('Error crear solicitud de reservación', error.response.data.message, 'warning');
              }
            });
      }
    },
  },
};
</script>