<template>
  <div>
      <v-card class="mx-auto rounded-lg" link @click="toRoom(booking)">
        <v-card-title class="title-user-scr" v-html="booking.title"></v-card-title>
        <v-card-subtitle
          v-html="booking.subtitle"
          class="pb-0"
          style="margin-bottom:15px"
        ></v-card-subtitle>
        <v-img
          class="white--text align-end rounded-lg"
          height="200px"
          :src="booking.imageUrl || imageDefaul"
        >
        </v-img>

        <v-card-text>
          <p v-html="booking.description"></p>
          <p v-if="!booking.noPayment" class="title-user-scr">{{$currency.format(booking.costAmount)}} MXN</p>
          <p v-if="booking.noPayment" class="title-user-scr">Sin costo de reservación</p>
        </v-card-text>

      </v-card>
  </div>
</template>

<script>
export default {
  props: {
    booking: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    imageDefaul() {
      return require('@/assets/images/avatar_dz.jpg');
    },
  },
  methods: {
    toRoom(booking) {
      this.$store.dispatch('room/setRoom', booking);
      this.$router.push({ name: 'bookingPlaceInformation', query: { booking: booking.id } })      
    }
  },
};
</script>
