<template>
  <div>
    <div style="display: flex; align-items: center; justify-content: center">
      <v-avatar size="100" style="line-break: auto">
        <img alt="" :src="profile.imageUrl || userImageDefaul" />
      </v-avatar>
    </div>
    <div style="display: flex; align-items: center; justify-content: center; padding-top: 10px; padding-bottom: 25px">
      <span class="title-user-scr">{{ profile.username }}</span>
    </div>
    Actualización: <small>{{ $dayjs(profile.updatedAt).format('ll') }}</small>

    <v-form v-if="profile.id" ref="formProfile" v-model="validFormProfile" lazy-validation>
      <ProfileForm ref="editUser" :info-user="profile" @ready-to-save="readyToSave = $event" />
    </v-form>
    <br />
    <v-btn block color="primary" :disabled="!validFormProfile" large outlined rounded @click="saveCurrentUser"
      >Actualizar perfil</v-btn
    >
    <br />
    <v-btn block color="primary" large outlined rounded @click="logout">Cerrar sesión</v-btn>
  </div>
</template>


<script>
import userService from '@/services/users';
import ProfileForm from '@/components/ProfileForm.vue';

export default {
  components: { ProfileForm },
  data() {
    return {
      profile: {},
      validFormProfile: true,
    };
  },
  created() {
    this.fetchProfile();
  },
  computed: {
    userImageDefaul() {
      return require('@/assets/images/avatar_dz.jpg');
    },
    isAdmin() {
      return this.$store.getters['session/user'].role === 'admin'
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('session/logout');
    },
    fetchProfile() {
      userService.user().then((response) => {
        this.profile = response.data;
      });
    },
    saveCurrentUser() {
      if (this.$refs.formProfile.validate()) {
        const formData = this.$refs.editUser.userFormData();
        userService
          .updatePerfil(formData.user)
          .then(() => {
            this.$swal('Perfil actualizado', 'Perfil actualizado con éxito', 'success');
            this.fetchProfile();
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              this.$swal('Error al actualizar perfil', error.response.data.message, 'warning');
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>