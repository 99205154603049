
<template>
   <div class="padding-card-c grey">
    <v-row>
      <v-select
            v-model="planId"
            :items="paymentPlans"
            item-text="concept"
            item-value="id"
            label="Plan de Pagos"
          ></v-select>
    </v-row>

    <div v-if="planId" style="margin-bottom: 30px">
      <button-download :progress="progress" @generateReport="downloadPdf()"  />
    </div>

    <vue-html2pdf
        :show-layout="contentRendered"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="cuotas_mant"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="letter"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        @progress="onProgress($event)"
        @startPagination="startPagination()"
        @hasPaginated="hasPaginated()"
        ref="html2Pdf"
    >
        
       <cuotas-report 
        v-if="(planId !== null)" 
        :payments="payments" 
        :plan="planSelected"
        @domRendered="domRendered()" 
        slot="pdf-content"/> 
    </vue-html2pdf>

    <CuotasReport/>
    
  </div>
</template>



<script>

import MaintenancePayService from '@/services/admin/maintenancePayments';
import VueHtml2pdf from 'vue-html2pdf';
import CuotasReport from '@/components/reports/CuotasReport';
import ButtonDownload from '@/components/reports/ButtonDownload';

export default {
  props: {
    request: {
      type: Array,
      default: () => [],
    },
  },
  components:{
    VueHtml2pdf,
    CuotasReport,
    ButtonDownload
  },
  data: () => ({
    page: 1,
    payments: [],
    paymentPlans: [],
    tosearch: null,
    planId: null,
    planSelected: null,
    contentRendered: false,
    progress: 0,
    generatingPdf: false,
    pdfDownloaded: false,
  }),
  created() {
    this.getPaymentPlans();
  },
  computed: {
    userImageDefaul() {
      return require('@/assets/images/avatar_dz.jpg');
    },
    length() {
      return Math.ceil(this.payments.length / 10);
    },

    shownUsersCards() {
      const { page, users } = this;
      const number = 10; // Numero de elementos a mostrar por página
      return users.slice((page - 1) * number, page * number);
    },
  },
  methods: {
    getPayments(id) {
      MaintenancePayService.fetchByPlan(id).then((response) => {
        this.payments = response.data;
      });
    },
    getPaymentPlans() {
      MaintenancePayService.fetch().then((response) => {
        this.paymentPlans = response.data;
      });
    },
     onProgress(progress) {
      this.progress = progress;
      console.log(`PDF generation progress: ${progress}%`)
    },
    startPagination() {
      console.log(`PDF has started pagination`)
    },
    hasPaginated () {
      console.log(`PDF has been paginated`)
    },
    
    async downloadPdf() {
      var opt = {
            pagebreak: {
                mode: ['avoid-all', 'css', 'legacy']
            }
        };
      this.$refs.html2Pdf.generatePdf(opt);
    },
   
  },
  watch:{
    planId(newValue) {
      this.planSelected = this.paymentPlans.find((plan)=>plan.id == newValue);
      console.log(this.planSelected);
      this.getPayments(newValue);
    }
  }
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>