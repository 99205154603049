<template>
  <div>
    <div style="display: flex; align-items: left; justify-content: left; padding-bottom: 25px">
      <span class="title-user-scr-b">Elige un salón</span>
    </div>
    <div v-for="(booking, i) in booking" :key="i" style="padding-bottom: 25px">
      <BookingCard :booking="booking" />
    </div>
  </div>
</template>


<script>
import roomService from '@/services/users/partyRooms';

import BookingCard from '@/components/user/BookingCard.vue';
export default {
  components: {
    BookingCard,
  },
  data() {
    return {
      booking: [],
    };
  },
  created() {
    this.fetchRooms();
  },
  methods: {
    fetchRooms() {
      roomService.fetch().then((response) => {
        this.booking = response.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>