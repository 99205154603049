<template>
  <v-app>
    <div class="grey">
      <v-row class="padding-card-c">
        <v-col cols="4">
          <v-card class="card-currency">
            <span>Monto de egresos del periodo</span>
            <p class="title-modal">{{
              $currency.format(total)
            }}</p>
          </v-card>

          <v-card class="card-currency" style="padding-bottom:30px; margin-top:40px">
            <p class="title-user-scr-b">Registrar nuevo egreso</p>
            <RegisterSpendForm @on-save="fetchPayments(dates)" />
          </v-card>
        </v-col>
        <v-col cols="8">
          <v-container>
            <v-row dense>
              <DateSelect :initialRage="dates" @change="fetchPayments" />
              <v-col v-for="(spend, i) in shownCards" :key="i" cols="12">
                <v-card color="white">
                  <div class="d-flex flex-no-wrap justify-space-between">
                    <v-list-item>
                      <v-list-item-avatar>
                        <img alt="profile" :src="imageDefaul" />
                      </v-list-item-avatar>

                      <v-list-item-content style="margin-left: 15px">
                        <v-list-item-title
                          class="title-text-info"
                        >{{spend.concept.split(' ').slice(0, 5).join(' ') + '...'}}</v-list-item-title>
                        <v-list-item-subtitle>{{spend.description.split(' ').slice(0, 5).join(' ') + '...'}}</v-list-item-subtitle>
                        <span style="font-size: 12px" v-html="$dayjs(spend.createdAt).format('llll')"></span>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-list-item-action-text class="title-text-info">{{
                          $currency.format(spend.amount)
                        }}</v-list-item-action-text>
                        <ModalSpend :post="spend" @on-update="fetchPayments(dates)" @on-close="fetchPayments(dates)"/>
                      </v-list-item-action>
                    </v-list-item>
                  </div>
                </v-card>
              </v-col>
            </v-row>
             <div class="text-center">
      <v-pagination v-model="page" :length="length"></v-pagination>
    </div>
          </v-container>
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>

<script>
import adminSuppliersService from '@/services/admin/supplierPayments';

import RegisterSpendForm from '@/components/RegisterSpendForm.vue';
import ModalSpend from '@/components/ModalSpend.vue';
import DateSelect from '@/components/DateSelect.vue';
export default {
  props: {
    request: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    RegisterSpendForm,
    ModalSpend,
    DateSelect,
  },
  data() {
    return {
    page: 1,
    paymentItems: [],
    dates: [this.$dayjs().subtract(30, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')],
  }
  },
  created() {
    this.fetchPayments(this.dates);
  },
  computed: {
    total() {
      if (!this.paymentItems.length) return 0;
      const reducer = (a, b) => a + b.amount;
      return this.paymentItems.reduce(reducer, 0);
    },
    imageDefaul() {
      return require('@/assets/images/avatar_dz.jpg');
    },
    length() {
      return Math.ceil(this.paymentItems.length / 8);
    },

    shownCards() {
      const { page, paymentItems } = this;
      const number = 8; // Numero de elementos a mostrar por página
      return paymentItems.slice((page - 1) * number, page * number);
    },
  },
  methods: {
    fetchPayments(dates) {
      if (dates.length === 2) {
        this.dates = dates;
        const startDate = dates[0];
        const endDate = dates[1];
        adminSuppliersService.search({ startDate, endDate }).then((response) => {
          this.paymentItems = response.data;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>