<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="white" small fab v-bind="attrs" v-on="on" height="30px" width="30px">
        <v-icon color="#00aff2">mdi-eye</v-icon>
      </v-btn>
    </template>
    <v-card>
    <div class="padding-card">
        <p class="title-modal">Información del pago realizado</p>
      <v-form ref="formSpend" v-model="validFormSpend" lazy-validation>
        <v-text-field label="Concepto de pago" v-model="post.concept"></v-text-field>
         <v-autocomplete
      item-text="name"
      item-value="id"
      label="Tipo de pago"
      v-model="post.paymentTypeId"
      :items="paymentItems"
    ></v-autocomplete>
        <v-text-field label="Proveedor" v-model="post.supplier"></v-text-field>
        <v-textarea label="Descripción" filled outlined v-model="post.description"></v-textarea>
        <v-text-field prefix="$" suffix="MXN" label="Importe pagado" v-model="post.amount"></v-text-field>
      </v-form>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="updateSpend"> Actualizar </v-btn>
        <v-btn color="blue darken-1" text @click="dialog = false; $emit('on-close')"> Cerrar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import adminSuppliersService from '@/services/admin/supplierPayments';

export default {
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {},
  data: () => ({
    dialog: false,
    validFormSpend: null,
    paymentItems: [],
  }),
  mounted () {
    this.paymentTypes();
  },
  methods: {
    paymentTypes() {
      adminSuppliersService.paymentTypes().then((response) => {
        this.paymentItems = response.data;
      });
    },
    updateSpend() {
      if (this.$refs.formSpend.validate()) {
        const amount = this.post.amount * 100;
        if (!Number.isInteger(amount)) {
          this.$swal(
            'Actualización de egreso',
            'El cargo debe tener a lo más dos decimales.',
            'warning'
          );
          return;
        }
      const data = {
        concept: this.post.concept,
        description: this.post.description,
        paymentTypeId: this.post.paymentTypeId,
        supplier: this.post.supplier,
        amount: amount,
      };
        adminSuppliersService
          .update(this.post.id, data)
          .then(() => {
            this.$swal('Egreso actualizado', 'El egreso se ha actualizado con éxito', 'success');
            this.$emit('on-update');
          })
          .catch((error) => {
            if (error.response && error.response.status === 400) {
              this.$swal('Error al actualizar egreso', error.response.data.message, 'warning');
            }
          });
      }
    },
    
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>