<template>
     <section class="pdf-content">  
       <h3 style="color:#787878">{{ plan.concept }}</h3>  
       <h5 style="color:#787878">Reporte emitido por App Dzidzil-há</h5>   
        <v-simple-table style="margin:30px 5px">          
            <thead>
              <tr>
                <th class="text-left">
                  Lote
                </th>
                <th class="text-left">
                  Nombre
                </th>
                <th class="text-left">
                  Ene
                </th>
                <th class="text-left">
                  Feb
                </th>
                <th class="text-left">
                  Mar
                </th>
                <th class="text-left">
                  Abr
                </th>
                <th class="text-left">
                  May
                </th>
                <th class="text-left">
                  Jun
                </th>
                <th class="text-left">
                  Jul
                </th>
                <th class="text-left">
                  Ago
                </th>
                <th class="text-left">
                  Sep
                </th>
                <th class="text-left">
                  Oct
                </th>
                <th class="text-left">
                  Nov
                </th>
                <th class="text-left">
                  Dic
                </th>
              </tr>
            </thead>
            <tbody>
            <tr v-for="item in payments" :key="item.id" >
                <td style="width: 10%">{{ item.user.usernameLogin }}</td>
                <td style="width: 30%">{{ item.user.username }}</td>
                <td style="width: 5%">
                  <span v-if="(item.Ene === 'paid')"><v-icon style="color:green; font-size:12px">mdi-check</v-icon></span>
                  <span v-if="(item.Ene === 'pending')"><v-icon style="color:red; font-size:12px">mdi-close</v-icon></span>
                </td> 
                <td style="width: 5%">
                  <span v-if="(item.Feb === 'paid')"><v-icon style="color:green; font-size:12px">mdi-check</v-icon></span>
                  <span v-if="(item.Feb === 'pending')"><v-icon style="color:red; font-size:12px">mdi-close</v-icon></span>
                </td>
                <td style="width: 5%">
                  <span v-if="(item.Mar === 'paid')"><v-icon style="color:green; font-size:12px">mdi-check</v-icon></span>
                  <span v-if="(item.Mar === 'pending')"><v-icon style="color:red; font-size:12px">mdi-close</v-icon></span>
                </td>
                <td style="width: 5%">
                  <span v-if="(item.Abr === 'paid')"><v-icon style="color:green; font-size:12px">mdi-check</v-icon></span>
                  <span v-if="(item.Abr === 'pending')"><v-icon style="color:red; font-size:12px">mdi-close</v-icon></span>
                </td>
                <td style="width: 5%">
                  <span v-if="(item.May === 'paid')"><v-icon style="color:green; font-size:12px">mdi-check</v-icon></span>
                  <span v-if="(item.May === 'pending')"><v-icon style="color:red; font-size:12px">mdi-close</v-icon></span>
                </td>
                <td style="width: 5%">
                  <span v-if="(item.Jun === 'paid')"><v-icon style="color:green; font-size:12px">mdi-check</v-icon></span>
                  <span v-if="(item.Jun === 'pending')"><v-icon style="color:red; font-size:12px">mdi-close</v-icon></span>
                </td>
                <td style="width: 5%">
                  <span v-if="(item.Jul === 'paid')"><v-icon style="color:green; font-size:12px">mdi-check</v-icon></span>
                  <span v-if="(item.Jul === 'pending')"><v-icon style="color:red; font-size:12px">mdi-close</v-icon></span>
                </td>
                <td style="width: 5%">
                  <span v-if="(item.Ago === 'paid')"><v-icon style="color:green; font-size:12px">mdi-check</v-icon></span>
                  <span v-if="(item.Ago === 'pending')"><v-icon style="color:red; font-size:12px">mdi-close</v-icon></span>
                </td>
                <td style="width: 5%">
                  <span v-if="(item.Sep === 'paid')"><v-icon style="color:green; font-size:12px">mdi-check</v-icon></span>
                  <span v-if="(item.Sep === 'pending')"><v-icon style="color:red; font-size:12px">mdi-close</v-icon></span>
                </td>
                <td style="width: 5%">
                  <span v-if="(item.Oct === 'paid')"><v-icon style="color:green; font-size:12px">mdi-check</v-icon></span>
                  <span v-if="(item.Oct === 'pending')"><v-icon style="color:red; font-size:12px">mdi-close</v-icon></span>
                </td>
                <td style="width: 5%">
                  <span v-if="(item.Nov === 'paid')"><v-icon style="color:green; font-size:12px">mdi-check</v-icon></span>
                  <span v-if="(item.Nov === 'pending')"><v-icon style="color:red; font-size:12px">mdi-close</v-icon></span>
                </td>
                <td style="width: 5%">
                  <span v-if="(item.Dic === 'paid')"><v-icon style="color:green; font-size:12px">mdi-check</v-icon></span>
                  <span v-if="(item.Dic === 'pending')"><v-icon style="color:red; font-size:12px; font-size:12px">mdi-close</v-icon></span>
                </td>              
              </tr> 
            </tbody>        
      </v-simple-table>
    </section>
</template>
<script>
export default {
    props: {
        payments: {
        type: Object,
        default: () => {},
      },
      plan: {
        type: Object
      }
    },
    
}
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>