import { apiClient } from '../index';

/**
 * [Obtiene todas las configuracione]
 * @return {[type]}          [description]
 */
 function fetch() {
  return apiClient.get('/v1/config');
}

/**
 * [Obtiene todas las configuracione]
 * @return {[type]}          [description]
 */
 function updateSurcharges(surchargeType, surchargeValue) {
  return apiClient.patch('/v1/config/payments/surcharges', { surchargeType, surchargeValue });
}
function updateBlocksize(reservationBlockSize) {
  return apiClient.patch(`/v1/config/reservations/blocksize/${reservationBlockSize}`);
}

export default { fetch, updateSurcharges, updateBlocksize };