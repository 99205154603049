import { apiClient } from '../index';

/**
 * [Crea una noticia]
 * @return {[type]}          [description]
 */
function create(formData) {
  return apiClient.post('/v1/news', formData, {
    headers: {
    'Content-Type': 'multipart/form-data;',
    }
  });
}

/**
 * [Obtiene todas las noticias]
 * @return {[type]}          [description]
 */
 function fetch() {
  return apiClient.get('/v1/news');
}

/**
 * [Switch el campo deleted true/false]
 * @return {[type]}          [description]
 */
 function switchdel(id) {
  return apiClient.patch('/v1/news/switchdel', 
    { id:id } );
}



export default { create, fetch, switchdel };