<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        dense
        prepend-icon="mdi-calendar-outline"
        v-model="date"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker :min="min" :max="max" :allowed-dates="allowedDates" v-model="date" scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false"> Cancelar </v-btn>
      <v-btn
        text
        color="primary"
        @click="
          $refs.menu.save(date);
          $emit('change', date);
        "
      >
        Seleccionar
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>


<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    min: {
      type: String,
      default: '',
    },
    max: {
      type: String,
      default: '',
    },
    allowedDates: {
      type: Function,
      default: null,
    },
    initialDate: {
      type: String,
      default: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    },
  },
  data() {
    return {
      date: this.initialDate
        ? this.initialDate
        : new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      menu: false,
      modal: false,
    };
  },
};
</script>