<template>
  <div class="wrapper" id="app">
    <CardForm
      v-if="isLoaded"
      :form-data="formData"
      :loading="loading"
      @save-card="save"
    />
      <!-- @input-card-number="updateCardNumber"
      @input-card-month="updateCardMonth"
      @input-card-year="updateCardYear"
      @input-card-cvv="updateCardCvv" -->
  </div>
</template>

<script>
import CardForm from '@/components/user/CardForm.vue';
import conektaService from '@/services/users/conekta';

export default {
  name: 'app',
  components: {
    CardForm,
  },
  data() {
    return {
      isLoaded: false,
      loading: false,
      formData: {
        cardName: '',
        cardNumber: '',
        cardMonth: '',
        cardYear: '',
        cardCvv: '',
      },
    };
  },
  methods: {
    save({ cardCvv, cardMonth, cardName, cardNumberNotMask, cardYear }) {
      this.loading = true;
      const success = (event) => {
        conektaService
          .createCardToPay(event.id)
          .then((response) => {
            this.loading = false;
            console.log('response.data', response.data);
            this.$router.push({ name: 'cardsuser' });
          })
          .catch((error) => {
            this.loading = false;
            if (error.response && [400, 404, 409].includes(error.response.status)) {
              this.$swal('Error al registrar tarjeta', error.response.data.message, 'warning');
            }
          });
      };
      const error = (event) => {
        this.loading = false;
        this.$swal('Operación cancelada', event.message_to_purchaser, 'error');
      };
      this.tokenize(cardNumberNotMask, cardName, cardMonth, cardYear, cardCvv, success, error);
    },
    initConekta: () => {
      window.Conekta.setPublicKey(process.env.VUE_APP_KEY_CNKTA);
    },
    getCardBrand: (cardNumber) => {
      return window.Conekta.card.getBrand(cardNumber);
    },
    validateCardNumber: (cardNumber) => {
      return window.Conekta.card.validateNumber(cardNumber);
    },
    validateCvc: (cvc) => {
      return window.Conekta.card.validateCVC(cvc);
    },
    validateExpirationDate: (expiryMonth, expiryYear) => {
      return window.Conekta.card.validateExpirationDate(expiryMonth, `20${expiryYear}`);
    },
    tokenize: (cardNumber, cardHolder, expiryMonth, expiryYear, cvc, successCallback, errorCallback) => {
      const tokenParams = {
        card: {
          number: cardNumber,
          name: cardHolder,
          exp_year: expiryYear,
          exp_month: expiryMonth,
          cvc,
        },
      };
      window.Conekta.Token.create(tokenParams, successCallback, errorCallback);
    },
    updateCardNumber(val) {
      this.validateCardNumber(val);
      this.getCardBrand(val);
    },
    // updateCardName (val) {
    //   console.log('value', val);
    // },
    // updateCardMonth(val) {
    //   console.log('value', val);
    // },
    // updateCardYear(val) {
    //   console.log('value', val);
    // },
    // updateCardCvv(val) {
    //   console.log('value', val);
    //   console.log('value', this.validateCvc(val));
    // },
    loadConekta: function () {
      const script = document.createElement('script');
      script.src = 'https://cdn.conekta.io/js/latest/conekta.js';
      script.id = 'conekta-script';

      const self = this;
      script.onload = function () {
        if (!window.Conekta) return console.error('Conekta is not set');
        self.isLoaded = true;
        self.initConekta();
      };

      script.async = true;
      document.body.appendChild(script);
    },
  },
  created() {
    if (!this.isLoaded) {
      this.loadConekta();
    }
  },
};
</script>

<style lang="scss">
@import './src/assets/style.scss';
</style>
