import { apiClient } from '../index';
/**
 * [Obtiene los salones de fiestas]
 * @return {[type]}          [description]
 */
function fetch() {
  return apiClient.get('/v1/party-rooms');
}

export default { fetch };
