<template>
    <div>
     <button
        :disabled="isGenerating"
        class="v-btn v-btn--has-bg theme--dark v-size--default" 
        style="background-color: rgb(0, 175, 242); border-color: rgb(0, 175, 242);"
        @click="$emit('generateReport')"
    >
            
        <span class="v-btn__content"> {{ isGenerating ? 'Loading...' : 'PDF' }}</span>
        </button>

        <section v-if="progress !== 0" class="progress-container">
            <div class="progress-bar">
                <div
                    class="progress"
                    :style="`width: ${progress}%;`"
                />
            </div>

            <section class="generating-label" v-if="progress !== 0">
                <span class="label-title">
                    {{ progress === 100 ? 'PDF Generated' : 'Generating PDF' }}
                </span>

                <span class="label-progress">
                    {{ `${ progress } %` }}
                </span>
            </section>
        </section>
    </div>
</template>
<script>
export default {
    props: {
        progress: {
            type: Number,
            default: 0
    },
    computed: {
        isGenerating () {
            return this.progress !== 0 && this.progress !== 100
        }
    },
  }
}
</script>

<style lang="scss" scoped>
.progress-container {
  width: 100%;
  margin-top: 10px;
  .progress-bar {
      width: 100%;
      height: 10px;
      border: 1px solid #585858;
      border-radius: 5px;
      margin-bottom: 5px;
      .progress {
          width: 0%;
          height: 100%;
          transition: 0.3s;
          background: #585858;
      }
  }
  .generating-label {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #585858
  }
}


</style>