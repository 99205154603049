<template>
  
    <v-dialog v-model="dialog" persistent max-width="800px">
      <template v-slot:activator="{ on, attrs }">
          <div>
            <v-btn small fab height="30px" width="30px" color="white" v-bind="attrs" v-on="on">
              <v-icon color="primary">mdi-pencil</v-icon>
            </v-btn>
        </div> 
      </template>

      <v-card>
        <div style="padding: 25px 25px 25px 25px">
          <p class="font-weight-medium text-h6" style="color: #00aff2">Editar evento</p>
          <ReservationForm @on-save="close" :book="reservation" />
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  
</template>

<script>
import ReservationForm from '@/components/ReservationEditForm.vue';

export default {
  name: 'ModalEditBooking',
  components: {
    ReservationForm,
  },
  props: {
    reservation: {
      type: Object,
      required: true,
    },
  }, 
  data: () => ({
    dialog: false,
  }),
  mounted () {
   console.log(this.reservation);
  },
  methods: {
    close(book) {
      console.log(book);     
      this.$emit('update');
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>