 <template>
  <div>
    <v-row>
      <v-file-input
        v-if="!isAdmin"
        ref="uploadImage"
        @change="selectFile"
        accept="image/*"
        label="Seleccionar una foto"
      ></v-file-input>

      <v-col cols="12">
        <v-text-field :disabled="modeEdit" dense v-model="usernameLogin" label="Usuario*"></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field 
          :disabled="!isAdmin"
          dense :rules="rules.name" 
          v-model="username"           
          label="Nombre completo"></v-text-field>
      </v-col>
      <v-col cols="7">
        <v-text-field
          dense
          :disabled="!isAdmin"
          :rules="[rules.required]"
          v-model="lotPurchased"
          label="Lote comprado*"
        ></v-text-field>
      </v-col>
      <v-col cols="5">
        <v-text-field
          dense
          :rules="[rules.mobileReq]"
          v-model="mobilePhone"
          placeholder="999-999-9999"
          name="celular"
          label="Celular*"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field 
          dense 
          placeholder="correo@mail.com"
          :rules="rules.email" 
          v-model="email" 
          label="Correo electrónico*"></v-text-field>
      </v-col>
      <v-col md="6">
        <v-text-field
          dense
          :rules="modeEdit && !password.length ? [] : [rules.required, rules.min]"
          :type="show1 ? 'text' : 'password'"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          v-model="password"
          label="Contraseña"
          @click:append="show1 = !show1"
        ></v-text-field>
      </v-col>
      <v-col md="6">
        <v-text-field
          dense
          :rules="modeEdit && !password.length ? [] : [rules.required, rules.min]"
          :type="show2 ? 'text' : 'password'"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          v-model="confirmPassword"
          label="Confirmar contraseña"
          @click:append="show2 = !show2"
        ></v-text-field>
      </v-col>     
    </v-row>

    <v-text-field dense v-model="address" label="Dirección"></v-text-field>

    <v-row>
      <v-col md="6">
        <v-text-field dense v-model="workplace" label="Lugar de trabajo"></v-text-field>
      </v-col>  
      <v-col md="6">
        <v-text-field 
          dense 
          :rules="rules.mobile" 
          placeholder="999-999-9999"
          v-model="officePhone" 
          label="Teléfono de oficina"></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field dense :rules="rules.lonmd" v-model="contactName" label="Nombre contacto"></v-text-field>
      </v-col>      
      <v-col cols="6">
        <v-text-field
          dense        
          :rules="rules.mobile" 
          v-model="contactMobile"
          placeholder="999-999-9999"
          name="celular"
          label="Celular contacto"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field dense :rules="rules.emailContact" v-model="contactEmail" label="Correo contacto"></v-text-field>
      </v-col>    

    </v-row>

<div v-if="isAdmin">
  <p class="title-user-scr">Saldos iniciales</p>
    <v-text-field prefix="$" suffix="MXN" dense v-model="lotInitialDeb" label="Saldo inicial lote residencial"></v-text-field>
    <v-text-field prefix="$" suffix="MXN" dense v-model="maintInitialDeb" label="Saldo inicial cuotas de mantenimiento"></v-text-field>
</div>

  </div>
</template>
 
<script>
export default {
  name: 'UserForm',
  props: {
    infoUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show1: false,
      show2: false,
      role: 'user',
      usernameLogin:'',
      username: '',
      email: '',
      lotPurchased: '',
      lotInitialDeb: '',
      maintInitialDeb: '',
      mobilePhone: '',
      password: '',
      confirmPassword: '',
      address: '',
      workplace: '',
      officePhone: '',
      image: '',
      statusSocio: ['normal', 'moroso'],
      rules: {
        required: (value) => !!value || 'Required.',
       
        name: [
          (v) => !!v || 'Nombre is required',
          (v) => (v && v.length <= 60) || 'Nombre debe ser menor a 60 caracteres ',
          (v) => (v && v.length >= 2 )|| 'Nombre debe ser mayor a 2 caracteres ',
        ],
        min: (v) => (v && v.length >= 8) || 'Min 8 characters',
        emailMatch: () => `The email and password you entered don't match`,
        email: [(v) => !!v || 'E-mail is required', (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'],
        mobileReq: [
            (v) => !!v || 'Celular requerido',
            (v) => !v || /^[0-9\-()]*$/.test(v) || 'Celular Invalido. caráteres permitidos: [ 9-0, - ]',
          ],
        mobile: [ (v) => !v || /^[0-9\-()]*$/.test(v) || 'Celular Invalido. caráteres permitidos: [ 9-0, - ]'],
        lonmd: [ (v) => !v || v.length <= 60 || 'Nombre debe ser menor a 60 caracteres' ],
        emailContact: [ (v) => !v || /.+@.+\..+/.test(v) || 'E-mail must be valid' ],
      },
      contactName:'',
      contactEmail:'',
      contactMobile:'',
    };
  },
  mounted() {
    if (this.infoUser.id) {
      this.role = this.infoUser.role;
      this.usernameLogin = this.infoUser.usernameLogin;
      this.username = this.infoUser.username;
      this.email = this.infoUser.email;
      this.lotPurchased = this.infoUser.lotPurchased;
      this.lotInitialDeb = this.infoUser.lotInitialDeb || 0;
      this.maintInitialDeb = this.infoUser.maintInitialDeb || 0;
      this.mobilePhone = this.infoUser.mobilePhone;
      this.password = this.infoUser.password || '';
      this.confirmPassword = this.infoUser.confirmPassword || '';
      this.address = this.infoUser.address;
      this.workplace = this.infoUser.workplace;
      this.officePhone = this.infoUser.officePhone;
      this.contactName = this.infoUser.contactName;
      this.contactEmail = this.infoUser.contactEmail;
      this.contactMobile = this.infoUser.contactMobile;
    }
  },
  watch: {
    infoUser(newValue) {
      if (newValue.id) {
        this.role = this.infoUser.role;
        this.usernameLogin = this.infoUser.usernameLogin;
        this.username = this.infoUser.username;
        this.email = this.infoUser.email;
        this.lotPurchased = this.infoUser.lotPurchased;
        this.lotInitialDeb = this.infoUser.lotInitialDeb || 0;
        this.maintInitialDeb = this.infoUser.maintInitialDeb || 0;
        this.mobilePhone = this.infoUser.mobilePhone;
        this.password = this.infoUser.password || '';
        this.confirmPassword = this.infoUser.confirmPassword || '';
        this.address = this.infoUser.address;
        this.workplace = this.infoUser.workplace;
        this.officePhone = this.infoUser.officePhone;
        this.contactName = this.infoUser.contactName;
        this.contactEmail = this.infoUser.contactEmail;
        this.contactMobile = this.infoUser.contactMobile;
      }
    },
  },
  computed: {
    modeEdit() {
      return this.infoUser.usernameLogin ? true : false;
    },
    isAdmin() {
      return this.$store.getters['session/user'].role === 'admin';
    },
  },
  methods: {
    selectFile(file) {
      this.image = file;
    },
    userFormData() {
      if (this.modeEdit) {
        const formData = new FormData();

        if (this.isAdmin) {
          formData.append('lotPurchased', this.lotPurchased);
        }
        if (this.image) {
          formData.append('image', this.image, this.image.name);
        }
        if (this.password) {
          formData.append('confirmPassword', this.confirmPassword);
          formData.append('password', this.password);
        }
       
        formData.append('username', this.username);
        formData.append('email', this.email) || '';
        formData.append('address', this.address) || '';
        formData.append('mobilePhone', this.mobilePhone) || '';
        formData.append('officePhone', this.officePhone) || '';
        formData.append('workplace', this.workplace) || '';
        formData.append('contactName', this.contactName) || '';
        formData.append('contactEmail', this.contactEmail) || '';
        formData.append('contactMobile', this.contactMobile) || '';
        formData.append('lotInitialDeb', this.lotInitialDeb) || 0;
        formData.append('maintInitialDeb', this.maintInitialDeb) || 0;
        return {
          id: this.infoUser.id,
          user: formData,
        };
      }
      return null;
    },
    userData() {
      if (this.modeEdit) {
        const user = {
            username: this.username,
            lotPurchased: this.lotPurchased,
            lotInitialDeb: this.lotInitialDeb || 0,
            maintInitialDeb: this.maintInitialDeb || 0,
            mobilePhone: this.mobilePhone || '',
            address: this.address || '',
            email: this.email || '',
            workplace: this.workplace || '',
            officePhone: this.officePhone || '',
            contactName: this.contactName || '',
            contactEmail: this.contactEmail || '',
            contactMobile: this.contactMobile || '',
          };
        if (this.password) {
        user.password = this.password;
        user.confirmPassword = this.confirmPassword;
        }
        return {
          id: this.infoUser.id,
          user,
        };
      }
      return {
        role: this.role,
        usernameLogin: this.usernameLogin,
        username: this.username,
        email: this.email,
        lotPurchased: this.lotPurchased,
        lotInitialDeb: this.lotInitialDeb || 0,
        maintInitialDeb: this.maintInitialDeb || 0,
        mobilePhone: this.mobilePhone || '',
        password: this.password,
        confirmPassword: this.confirmPassword,
        address: this.address || '',
        workplace: this.workplace || '',
        officePhone: this.officePhone || '',
        contactName: this.contactName || '',
        contactEmail: this.contactEmail || '',
        contactMobile: this.contactMobile || '',
      };
    },

    checkPassword () {
    if (this.password || this.confirmPassword) {
      if (this.password !== this.confirmPassword ) {
      return false;
      }
      return true;
    }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>