<template>
  <v-dialog v-model="dialog" persistent max-width="900px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" small v-bind="attrs" v-on="on" height="30px" width="30px">
        <v-icon color="white">mdi-account-multiple-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <div class="padding-card">
        <p class="title-modal">Usuarios suscritos</p>
        <v-form ref="formSuscribed" v-model="validForm" lazy-validation>
          <v-row>
            <v-col cols="12" sm="8">
              <v-autocomplete
                :disabled="checkbox"
                :rules="rules.userRequired"
                multiple
                v-model="userIds"
                :item-text="username"
                item-value="id"
                label="Usuarios"
                :items="usersAllow"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4">
              <v-checkbox v-model="checkbox" :disabled="!usersAllow.length" :label="`Suscribir a todos`"></v-checkbox>
            </v-col>
          </v-row>

          <v-btn @click="suscribe" depressed color="#00aff2" dark> Suscribir </v-btn>
        </v-form>
      </div>
      <v-data-table
        :headers="[
          { text: 'Nombre', value: 'username' },
          { text: 'Usuario', value: 'usernameLogin' },
          { text: 'Email', value: 'email' },
          { text: 'Lote', value: 'lotPurchased' },
        ]"
        :items="usersSuscribed"
        :items-per-page="5"
        class="elevation-1"
      ></v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false"> Cerrar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>




<script>
import adminUserService from '@/services/admin/users';
import adminScheduleMaintenanceService from '@/services/admin/maintenancePayments';

export default {
  props: {
    maintenanceFeeId: {
      type: Number,
      required: true,
    },
    year: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      validForm: true,
      suscribeds: [],
      checkbox: false,
      dialog: false,
      users: [],
      userIds: null,
      rules: {
        required: (value) => !!value || 'Required.',
        userRequired: [(v) => !!v || this.checkbox || 'User is required'],
      },
    };
  },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.getUsers();
        this.fetch();
        // this.$refs.formSuscribed.reset();
      }
    },
  },
  computed: {
    usersAllow() {
      return this.users.filter((user) => !this.suscribeds.includes(user.id));
    },
    usersSuscribed() {
      return this.users.filter((user) => this.suscribeds.includes(user.id));
    },
  },
  methods: {
    getUsers() {
      adminUserService.getUsers().then((response) => {
        this.users = response.data;
      });
    },
    username(user) {
      return `${user.username} [Lote: ${user.lotPurchased}]`;
    },
    fetch() {
      adminScheduleMaintenanceService.usersSuscribed(this.maintenanceFeeId).then((response) => {
        this.suscribeds = response.data;
      });
    },
    suscribe() {
      if (this.$refs.formSuscribed.validate()) {
        let userIds = this.userIds;
        if (this.checkbox) {
          userIds = this.usersAllow.map((user) => user.id);
        }
        adminScheduleMaintenanceService
          .suscribeBatch(this.maintenanceFeeId, userIds)
          .then((response) => {
            let message = '';
            response.data.results.forEach((res) => {
              if (!res.success) {
                message = 'No fue posible suscribir a algún usuario.';
              }
            });
            this.$refs.formSuscribed.reset();
            this.fetch();
            if (message.length) {
              this.$swal('Suscripciones', message,'warning');
              return;
            }
            this.$swal('Suscripciones', 'La suscripción a mantenimiento se realizo correctamente.', 'success');
          })
          .catch((error) => {
            if (error.response && error.response.status >= 400) {
              this.$swal('Error al suscribir', error.response.data.message, 'warning');
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>