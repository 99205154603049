import { apiClient } from './index';

/**
 * [login description] Autentifica un usuario en el sistema
 * @param  {[type]} usernameLogin    [description]
 * @param  {[type]} password [description]
 * @return {[type]}          [description]
 */
 function login(usernameLogin, password) {
  return apiClient.post('/v1/auth/authenticate', { usernameLogin, password });
}

/**
 * [register description] Autentifica un usuario en el sistema
 * @param  {[type]} newUser    [description]
 * @return {[type]}          [description]
 */
 function register(newUser) {
  return apiClient.post('/v1/auth/register', newUser);
}

/**
 * [login description] Autentifica un usuario en el sistema
 * @param  {[type]} email    [description]
 * @return {[type]}          [description]
 */
 function recover(email) {
  return apiClient.post(`/v1/users/password/recover/${email}`);
}

/**
 * [changePassword description] Autentifica un usuario en el sistema
 * @param  {[type]} token    [description]
 * @return {[type]}          [description]
 */
 function changePassword(token) {
  return apiClient.put(`/v1/users/password/change/${token}`);
}

export default { login, register, recover, changePassword };
