<template>
  <v-form ref="formReservation" v-model="validForm" lazy-validation>
    <v-text-field :rules="[rules.required]" v-model="title" label="Titulo"></v-text-field>
    <v-text-field :rules="[rules.required]" v-model="description" label="Descripción"></v-text-field>
    <v-row>
      <v-col sm="6">
        <v-autocomplete
          :rules="[rules.required]"
          v-model="customerId"
          :item-text="username"
          item-value="id"
          label="Cliente"
          :items="users"
        ></v-autocomplete>
      </v-col>
      <v-col sm="6">
        <v-autocomplete
          :rules="[rules.required]"
          v-model="partyRoomId"
          item-text="title"
          item-value="id"
          label="Salon"
          :items="rooms"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center mb-6">
      <v-col sm="6">
        <label for="date"
          ><DatePicker
            :min="min"
            :initialDate="min"
            label="Fecha de reservación"
            name="date"
            @change="reservationDate = $event"
        /></label>
      </v-col>
      <v-col sm="6">
        <v-checkbox v-model="allDayCheckbox" label="Todo el día"></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="6">
        <time-picker
          v-show="!allDayCheckbox"
          label="Hora de reservación"
          :rules="!allDayCheckbox ? [!allDayCheckbox && rules.required] : []"
          :initialHour="reservationHour"
          @change="reservationHour = $event"
        ></time-picker>
      </v-col>
      <v-col sm="6">
        <v-select
          v-show="!allDayCheckbox"
          v-model="lapse"
          :rules="!allDayCheckbox ? [!allDayCheckbox && rules.required] : []"
          :items="numbersHours"
          label="Duración"
        ></v-select>
      </v-col>
    </v-row>
    <v-container
        class="px-0"
        fluid
      >
    <v-switch
      v-model="withPendingPymts"
      :label="`Reservar con cuotas atrasadas  ${withPendingPymts.toString()}`"
    ></v-switch>
    <v-btn @click="save" depressed color="#00aff2" dark>Crear</v-btn>
  </v-container>
  </v-form>
</template>

<script>
import adminReservationsService from '@/services/admin/reservations';
import adminUserService from '@/services/admin/users';
import adminRoomsService from '@/services/admin/partyRooms';

import DatePicker from '@/components/DatePicker.vue';
import TimePicker from '@/components/TimePicker.vue';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);
dayjs.locale('es');

export default {
  components: {
    DatePicker,
    TimePicker,
  },
  data() {
    return {
      allDayCheckbox: false,
      validForm: true,
      rules: {
        min: (value) =>
          value >= this.$settingBlockSize || 'La duración debe ser mayor a ' + this.$settingBlockSize + ' horas',
        max: (value) => value <= 23 || 'La duración debe ser menor a 24 horas',
        required: (value) => !!value || 'Required.',
      },
      users: [],
      rooms: [],
      min: dayjs(Date.now()).add(1, 'd').format('YYYY-MM-DD'),
      reservationDate: dayjs(Date.now()).add(1, 'd').format('YYYY-MM-DD'),
      title: '',
      description: '',
      customerId: '',
      reservationHour: '',
      lapse: '',
      partyRoomId: '',
      withPendingPymts: false
    };
  },
  created() {
    this.getUsers();
    this.getRooms();
  },
  computed: {
    numbersHours() {
      return Array.from({ length: 24 - this.reservationHour }, (_, i) => i + 1);
    },
  },
  watch: {
    reservationHour(newValue) {
      if (newValue + this.lapse > 24)   {
        this.lapse = null;
      }    
    }
  },
  methods: {
    username(user) {
      return `${user.username} [${user.usernameLogin}]`;
    },
    save() {
      if (this.$refs.formReservation.validate()) {
        console.log('pagos pendientes');
        console.log(this.withPendingPymts);
        const data = {
          title: this.title,
          description: this.description,
          reservationDate: this.reservationDate,
          customerId: this.customerId,
          reservationHour: this.allDayCheckbox ? 0 : this.reservationHour,
          lapse: this.allDayCheckbox ? 24 : this.lapse,
          partyRoomId: this.partyRoomId,
          allowPendingPymts: this.withPendingPymts
        };
        adminReservationsService
          .create(data)
          .then((response) => {
            this.$refs.formReservation.reset();
            this.$emit('on-save', response.data);
            this.$swal('Reservación', 'La reservación ha sido creada correctamente.', 'success');
          })
          .catch((error) => {
            if (error.response && [400, 409].includes(error.response.status)) {
              this.$swal('Error al actualizar reservación', error.response.data.message, 'warning');
            }
          });
      }
    },
    getUsers() {
      const includeAdmin = true;
      adminUserService.getUsers(includeAdmin).then((response) => {
        this.users = response.data;
      });
    },
    getRooms() {
      adminRoomsService.fetch().then((response) => {
        this.rooms = response.data;
      });
    },
  },
};
</script>