<template>
  <div class="grey">
    <v-row class="padding-row-c">
      <v-col cols="12" sm="6">
        <v-card class="padding-card">
           <DateSelect :initialRage="dates" @change="fetchPayments" />
          <div style="padding-left: 25px">
            <span class="title-user-scr-b">Ingresos </span><br>
            <span class="title-modal">{{ $currency.format(total) }}</span>
            <br>

            <v-row>
               <v-col cols="4"> 
                  <span class="title-text-info">Mantenimiento </span><br>         
                  <span class="title-table-col">{{ $currency.format(totalIncome) }} </span> 
               </v-col> 

               <v-col cols="4"> 
                  <span class="title-text-info">Reservaciones </span> <br>           
                  <span class="title-table-col">{{ $currency.format(totalReservation ) }}</span>
               </v-col>   

               <v-col cols="4">
                  <span class="title-text-info">Compra Lote </span> <br>
                  <span class="title-table-col">{{ $currency.format(totalMonthly ) }}</span>
               </v-col>   
            </v-row>  
          </div>
          <p></p>
          <ChartHome v-if="income.values" :data="incomechart" />
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="padding-card">
          <DateSelect :initialRage="dates" @change="fetchPaymentsSpend" />
          <div style="padding-left: 25px">
            <p class="title-user-scr-b">Egresos</p>
            <span class="title-modal">{{
              $currency.format(totalSpend)
            }}</span>
          </div>
          <ChartHome v-if="expenses.values" :data="expenses" />
        </v-card>
      </v-col>
    </v-row>
    <v-row class="padding-card-d">
      <v-col cols="12" sm="6">
        <v-card class="padding-card">
          <p class="title-user-scr-b">Últimos boletines publicados</p>

          <v-carousel height="375">
            <v-carousel-item v-for="(item, i) in news" :key="i">
              <v-img
                class="fill-height"
                align="center"
                justify="center"
                :src="item.imageUrl"
                gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,1)"
              >
                <p class="white--text text-truncate" style="margin: 300px 25px 25px 25px" v-html="item.description" />
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="padding-card">
          <p class="title-user-scr-b">Últimas solicitudes de reservación</p>
          <CardEventRequest :request="requests" @accept="acceptToPay" @paid="confirm" @cancel="cancel"/>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import adminStatisticsService from '@/services/admin/statistics';

import adminReservationsService from '@/services/admin/reservations';
import adminNewService from '@/services/admin/news';
import ChartHome from '@/components/ChartHome.vue';
import CardEventRequest from '@/components/CardEventRequest.vue';


import adminUserService from '@/services/admin/users';
import adminScheduleMaintenanceService from '@/services/admin/maintenancePayments';
import adminMonthlyService from '@/services/admin/monthlyPayments';
import adminReservationService from '@/services/admin/reservations';

import DateSelect from '@/components/DateSelect.vue';
import adminSuppliersService from '@/services/admin/supplierPayments';

export default {
  props: {
    request: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ChartHome,
    CardEventRequest,
    DateSelect,
  },
    data() {
    return {
      news: [],
      reservations: [],
      reservationsRequest: [],
      incomechart: {},
      income: {},
      expenses: {},
      pageMaintenance:1,
      pageMontly:1,
      pageBooking:1,
      scheduleSelected: null,
      schedules: [],
      monthly: [],
      page: 1,
      paymentItems: [],
      dates: [this.$dayjs().subtract(30, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')],
    };
  },
   computed: {
     totalSpend() {
      if (!this.paymentItems.length) return 0;
      const reducer = (a, b) => a + b.amount;
      return this.paymentItems.reduce(reducer, 0);
    },
     total() {
       return this.totalIncome + this.totalMonthly + this.totalReservation;
    },
    totalIncome() {
      if (!this.income.length) return 0;
      const reducer = (a, b) => a + b.amount;
      return this.income.reduce(reducer, 0);
    },
    totalMonthly() {
      if (!this.monthly.length) return 0;
      const reducer = (a, b) => a + b.amount;
      return this.monthly.reduce(reducer, 0);
    },
    totalReservation() {
      if (!this.reservations.length) return 0;
      const reducer = (a, b) => {
        let result = a + b.room.costAmount;
        if (b.room.deposit && b.depositPaid) {
          result += b.room.depositAmount;
        }
        return result;
      };
      return this.reservations.reduce(reducer, 0);
    },
    imageDefaul() {
      return require('@/assets/images/avatar_dz.jpg');
    },
     totalExpenses() {
       if (!this.expenses.values) return 0;
       return this.expenses.values.reduce((a,b) => a+b);      
     },
    requests() {
      return this.reservationsRequest.filter((item) => item.status === 'pending');
    },
  },
  created() {
    this.getStatistics(this.dates);
    this.getNews();
    this.getReservationsRequest();
    this.fetchSchedulePayment(this.dates);
    this.fetchReservations(this.dates);
    this.getUsers();
    this.fetchPayments(this.dates);
    this.fetchPaymentsSpend(this.dates);
  },
  methods: {
    fetchPaymentsSpend(dates) {
      if (dates.length === 2) {
        this.dates = dates;
        const startDate = dates[0];
        const endDate = dates[1];
        adminSuppliersService.search({ startDate, endDate }).then((response) => {
          this.paymentItems = response.data;
        });
      }
    },
    fetchPayments(dates) {
      if (dates.length === 2) {
        this.dates = dates;
        //const startDate = dates[0];
        //const endDate = dates[1];
        this.fetchSchedulePayment();
        this.fetchReservations();
      }
    },
    fetchSchedulePayment() {
      const year = new Date(this.dates[1]).getFullYear();
      adminScheduleMaintenanceService.fetch().then((response) => {
        this.schedules = response.data;
        const search = this.schedules.filter((item) => item.paymentYear.toString() === year.toString());
        this.scheduleSelected = search.length ? search[0].id : null;
        this.fetch();
        this.fetchMonthly();
      });
    },
    getUserInfo(userId) {
      if (!this.users) { return '' }
      const user = this.users.filter( item => item.id === userId)[0];
      return user.username;
    },
    getUserAvatar(userId) {
      if (!this.users) { return '' }
      const user = this.users.filter( item => item.id === userId)[0];
      if (!user) return null;
      return user.imageUrl;
    },
    getUsers() {
      adminUserService.getUsers('all').then((response) => {
        this.users = response.data;
      });
    },
    username(user) {
      return `${user.username} [${user.email}]`;
    },
    fetch() {
      if (!this.scheduleSelected) return this.income;
      adminScheduleMaintenanceService
      .searchPayment('paid', null, this.scheduleSelected, this.dates[0], this.dates[1])
      .then((response) => {
        this.income = response.data;
      });
    },
    fetchMonthly() {
      adminMonthlyService.search(null, 'paid', this.dates[0], this.dates[1])
      .then((response) => {
        this.monthly = response.data;
      });
    },
    fetchReservations() {
      adminReservationService.search(null, null, 'paid', this.dates[0], this.dates[1])
      .then((response) => {
        this.reservations = response.data;
      });
    },
     getStatistics(dates) {
        this.dates = dates;
      adminStatisticsService.fetchIncome().then((response) => {
        this.incomechart = response.data;
      });
      adminStatisticsService.fetchExpenses().then((response) => {
        this.expenses = response.data;
      });
    },
    getNews() {
      adminNewService.fetch().then((response) => {
        this.news = response.data;
      });
    },
    getReservationsRequest() {
      adminReservationsService.search().then((response) => {
        this.reservationsRequest = response.data;
      });
    },
    acceptToPay(id) {
      adminReservationsService.acceptToPay(id).then(() => {
        this.getReservationsRequest();
      });
    },
    confirm(id) {
      adminReservationsService.confirm(id).then(() => {
        this.getReservationsRequest();
      });
    },
    cancel(id) {
      adminReservationsService.cancel(id).then(() => {
        this.getReservationsRequest();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>