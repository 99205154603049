<template>
  <v-row justify="end">
    <v-dialog v-model="dialog" persistent max-width="800px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" small fab dark v-bind="attrs" v-on="on">
          <v-icon>mdi-plus</v-icon>
        </v-btn>

        <span class="text" style="padding-top: 10px; padding-left: 15px"> Registrar evento </span>
      </template>

      <v-card>
        <div style="padding: 25px 25px 25px 25px">
          <p class="font-weight-medium text-h6" style="color: #00aff2">Registrar nuevo evento</p>
          <ReservationForm @on-save="close" />
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import ReservationForm from '@/components/ReservationForm.vue';

export default {
  name: 'ModalBooking',
  components: {
    ReservationForm,
  },
  data: () => ({
    dialog: false,
  }),

  methods: {
    close() {
      this.$emit('update');
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>