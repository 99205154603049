 <template>
  <div>    
    <p class="title-user-scr">Datos Administrador</p>  
    <v-row>
      <v-file-input
        v-if="!isAdmin"
        ref="uploadImage"
        @change="selectFile"
        accept="image/*"
        label="Seleccionar una foto"
      ></v-file-input>
      
      <v-col cols="12">
        <v-text-field dense :rules="rules.name" v-model="username" label="Nombre completo"></v-text-field>
      </v-col>
      
      <v-col cols="6">
        <v-text-field
          dense
          :rules="rules.mobileReq"
          v-model="mobilePhone"
          placeholder="999-999-9999"
          name="celular"
          label="Celular*"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field dense :rules="rules.email" v-model="email" label="Correo electrónico*"></v-text-field>
      </v-col>      
    </v-row>

    <p class="title-user-scr">Acceso App</p>  
    <v-col cols="12">
      <v-text-field :disabled="modeEdit" dense v-model="usernameLogin" label="Usuario*"></v-text-field>
    </v-col>
    <v-row>
      <v-col md="6">
        <v-text-field
          dense
          :rules="modeEdit && !password.length ? [] : [rules.required, rules.min]"
          :type="show1 ? 'text' : 'password'"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          v-model="password"
          label="Contraseña"
          @click:append="show1 = !show1"
        ></v-text-field>
      </v-col>
      <v-col md="6">
        <v-text-field
          dense
          :rules="modeEdit && !password.length ? [] : [rules.required, rules.min]"
          :type="show2 ? 'text' : 'password'"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          v-model="confirmPassword"
          label="Confirmar contraseña"
          @click:append="show2 = !show2"
        ></v-text-field>
      </v-col>
    </v-row>

    <p class="title-user-scr">Datos contacto secundario</p>  
    <v-row>   
      <v-col cols="12">
        <v-text-field dense :rules="rules.lonmd" v-model="contactName" label="Nombre completo"></v-text-field>
      </v-col>      
      <v-col cols="6">
        <v-text-field
          dense        
          :rules="rules.mobile" 
          v-model="contactMobile"
          placeholder="999-999-9999"
          name="celular"
          label="Celular"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field dense :rules="rules.emailContact" v-model="contactEmail" label="Correo electrónico"></v-text-field>
      </v-col>    
    </v-row>  
  </div>
</template>
 
<script>
export default {
  name: 'UserForm',
  props: {
    infoUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      show1: false,
      show2: false,
      role: 'user',      
      usernameLogin:'',
      username: '',      
      email: '',     
      mobilePhone: '',
      password: '',
      confirmPassword: '',     
      image: '',        
      rules: {
          required: (value) => !!value || 'Required.',
          name: [
            (v) => !!v || 'Nombre is required',
            (v) => (v && v.length <= 60) || 'Nombre debe ser menor a 60 caracteres',
            (v) => (v && v.length >= 2) || 'Nombre debe ser mayor a 2 caracteres',
          ],
          min: (v) => (v && v.length >= 8) || 'Min 8 characters',
          emailMatch: () => 'The email and password you entered don\'t match',

          email: [
            (v) => !!v || 'E-mail is required',
            (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
          ],          
          emailContact: [ (v) => !v || /.+@.+\..+/.test(v) || 'E-mail must be valid' ],
          lonsm: [ (v) => !v || v.length <= 30 || 'Nombre debe ser menor a 30 caracteres' ],
          lonmd: [ (v) => !v || v.length <= 60 || 'Nombre debe ser menor a 60 caracteres' ],
          lonlg: [ (v) => !v || v.length <= 100 || 'Nombre debe ser menor a 100 caracteres' ],
          mobileReq: [
            (v) => !!v || 'Celular requerido',
            (v) => !v || /^[0-9\-()]*$/.test(v) || 'Celular Invalido',
          ],
          mobile: [ (v) => !v || /^[0-9\-()]*$/.test(v) || 'Celular Invalido' ],
          onlyNumbers: [ (v) => !v || /^[0-9]*$/.test(v) || 'Solo números 0-9 son permitidos' ],
        },     
      contactName:'',
      contactEmail:'',
      contactMobile:'',      
    };
  },
  mounted() {
    if (this.infoUser.id) {
      this.role = this.infoUser.role;
      this.usernameLogin = this.infoUser.usernameLogin;
      this.username = this.infoUser.username;      
      this.email = this.infoUser.email;           
      this.mobilePhone = this.infoUser.mobilePhone;
      this.password = this.infoUser.password || '';
      this.confirmPassword = this.infoUser.confirmPassword || '';          
      this.contactName = this.infoUser.contactName;
      this.contactEmail = this.infoUser.contactEmail;
      this.contactMobile = this.infoUser.contactMobile;      
    }
  },
  watch: {
    infoUser(newValue) {
      if (newValue.id) {
        this.role = this.infoUser.role;
        this.usernameLogin = this.infoUser.usernameLogin;
        this.username = this.infoUser.username;       
        this.email = this.infoUser.email;      
        this.mobilePhone = this.infoUser.mobilePhone;
        this.password = this.infoUser.password || '';
        this.confirmPassword = this.infoUser.confirmPassword || '';              
        this.contactName = this.infoUser.contactName;
        this.contactEmail = this.infoUser.contactEmail;
        this.contactMobile = this.infoUser.contactMobile;       
      }
    },
  },
  computed: {
    modeEdit() {
      return this.infoUser.usernameLogin ? true : false;
    },
    isAdmin() {
      return this.$store.getters['session/user'].role === 'admin';
    },
  },
  methods: {
    selectFile(file) {
      this.image = file;
    },
    userFormData() {
      if (this.modeEdit) {
        const formData = new FormData();
        
        if (this.image) {
          formData.append('image', this.image, this.image.name);
        }
        if (this.password) {
          formData.append('confirmPassword', this.confirmPassword);
          formData.append('password', this.password);
        }        
        formData.append('username', this.username);        
        formData.append('email', this.email) || '';        
        formData.append('mobilePhone', this.mobilePhone) || '';       
               
        formData.append('contactName', this.contactName) || '';
        formData.append('contactEmail', this.contactEmail) || '';
        formData.append('contactMobile', this.contactMobile) || '';       

        return {
          id: this.infoUser.id,
          user: formData,
        };
      }
      return null;
    },
    userData() {
      if (this.modeEdit) {
        const user = {
            username: this.username,                   
            mobilePhone: this.mobilePhone || '',          
            email: this.email || '',                       
            contactName: this.contactName || '',
            contactEmail: this.contactEmail || '',
            contactMobile: this.contactMobile || '',            
          };
        if (this.password) {
        user.password = this.password;
        user.confirmPassword = this.confirmPassword;
        }
        return {
          id: this.infoUser.id,
          user,
        };
      }
      return {
        role: this.role,
        usernameLogin: this.usernameLogin,
        username: this.username,       
        email: this.email,       
        mobilePhone: this.mobilePhone || '',
        password: this.password,
        confirmPassword: this.confirmPassword,              
        contactName: this.contactName || '',
        contactEmail: this.contactEmail || '',
        contactMobile: this.contactMobile || '',      
      };
    },

    checkPassword () {
    if (this.password || this.confirmPassword) {
      if (this.password !== this.confirmPassword ) {
      return false;
      }
      return true;
    }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>