<template>
  <div class="padding-card-c grey">
    <div style="display: flex">
     <v-text-field
                  dense
                  v-model="tosearch"
                  label="buscar"
                  append-icon="mdi-account-search"
                  @click:append="searchUsers"
      
      ></v-text-field>       
      <ModalNewUser ref="modalNewUser" @save="saveNewUser" />           
    </div>
    <v-row v-for="(income, index) in shownUsersCards" :key="index">
      <v-col cols="10">
        <v-card color="white">
          <div class="d-flex flex-no-wrap justify-space-between">
            <v-list-item>
             <!--  <v-list-item-avatar>
               <img alt="" :src="income.imageUrl || userImageDefaul" />              
              </v-list-item-avatar>  -->             
              <v-list-item-content>
                <v-row>
                  <v-col cols="2">
                    <span>Lote: {{income.lotPurchased}}</span>
                  </v-col>
                  <v-col cols="3">
                   <span style="padding-left: 15px">{{income.mobilePhone}}</span>
                  </v-col> 
                  <v-col cols="4">
                    <span class="title-text-info" style="padding-left: 15px">{{income.username.split(' ').slice(0, 3).join(' ') + '...'}}</span>
                  </v-col>
                  <v-col cols="3">
                    <span>{{income.email}}</span>
                  </v-col>                 
                </v-row>
              </v-list-item-content>

              <v-list-item-action>
                <div style="display: flex">
                  <div style="padding-right: 10px">
                    <modal-user ref="modalEdit" :info-user="income" @save="saveCurrentUser"/>
                  </div>
                  <!-- <v-btn color="white" small fab height="30px" width="30px">
                    <v-icon @click="alertdeleteuser(income)" color="primary">mdi-delete</v-icon>
                  </v-btn> -->
                </div>
              </v-list-item-action>
            </v-list-item>
          </div>
        </v-card>
      </v-col>

      <v-col cols="2" style="display: flex">
        <div style="padding-right: 15px">
          <ModalMaintenancePlans :customer-id="income.id" :key="income.id"/>
        </div>
        <ModalMaintenancePayments :customer-id="income.id" :key="income.id"/>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-pagination v-model="page" :length="length"></v-pagination>
    </div>
  </div>
</template>



<script>
import authService from '@/services/auth';
import adminUserService from '@/services/admin/users';

import ModalUser from '@/components/ModalUser.vue';
import ModalNewUser from '@/components/ModalNewUser.vue';
import ModalMaintenancePlans from '../../components/ModalMaintenancePlans.vue';
import ModalMaintenancePayments from '@/components/ModalMaintenancePayments.vue';

export default {
  props: {
    request: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ModalMaintenancePlans,
    ModalUser,
    ModalNewUser,
    ModalMaintenancePayments,
  },
  data: () => ({
    page: 1,
    users: [],
    tosearch: null,
  }),
  created() {
    this.getUsers();
  },
  computed: {
    userImageDefaul() {
      return require('@/assets/images/avatar_dz.jpg');
    },
    length() {
      return Math.ceil(this.users.length / 10);
    },

    shownUsersCards() {
      const { page, users } = this;
      const number = 10; // Numero de elementos a mostrar por página
      return users.slice((page - 1) * number, page * number);
    },
  },
  methods: {
    saveCurrentUser(data) {
      adminUserService
        .updateUserPerfil(data.id, data.user)
        .then((response) => {
          console.log(response.data); // Log the response dat
          this.$refs.modalEdit[0].close();
          this.$swal('Perfil actualizado', 'El perfil se ha actualizado con éxito', 'success');
          this.getUsers();
        })
        .catch((error) => {
          if (error.response && [400, 409].includes(error.response.status)) {
            this.$swal('Error al actualizar perfil', error.response.data.message, 'warning');
          }
        });
    },
    saveNewUser(data) {
      authService
        .register(data)
        .then(() => {
          this.$refs.modalNewUser.close();
          this.$swal('Usuario guardado', 'El usuario se ha creado con éxito', 'success');
          this.getUsers();
        })
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            this.$swal('Error al registrar usuario', error.response.data.message, 'warning');
          }
          if (error.response && error.response.status === 500) {
            this.$swal('Error al registrar usuario', 'Por favor valide que el nombre no se encuentre duplicado', 'warning');
          }
        });
    },
    getUsers() {
      adminUserService.getUsers().then((response) => {
        this.users = response.data;        
        this.users.sort(function(a,b) {
            var x = a.lotPurchased.toLowerCase();
            var y = b.lotPurchased.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
        });
      });
    },
    searchUsers() {
       adminUserService.searchUsers(this.tosearch).then((response) => {
        this.users = response.data;
      });
    },
    alertdeleteuser(user) {
      // Use sweetalert2
      this.$swal({
        title: '¿Desea eliminar al usuario? ' + user.email,
        text: 'Al eliminar al usuario se eliminará toda la información relacionada a él',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, ¡eliminar al usuario!',
        cancelButtonText: 'No, ¡cancelar!',
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {

          this.$swal('Operación realizada', 'Se eliminó al usuario seleccionado', 'success');
        } else {
          this.$swal('Operación cancelada', 'No se eliminó al usuario seleccionado', 'error');
        }
      });
    },    
    loadCsv(){
      console.log('loadCsv');
      adminUserService.loadCsv().then((response) => {
        console.log(response);
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>