import { apiClient } from '../index';


/**
 * [Encuentra las mensualidades según los criterios de búsqueda]
 * @param {number} customerId El identificador del usuario
 * @return {[type]}          [description]
 */
function fetchIncome(startDate, endDate, limit = 100) {
  return apiClient.get('/v1/statistics/income', {
    params: {
      startDate,
      endDate,
      limit,
    },
  });
}

function fetchExpenses(startDate, endDate, limit = 100) {
  return apiClient.get('/v1/statistics/expenses', {
    params: {
      startDate,
      endDate,
      limit,
    },
  });
}

export default {
  fetchIncome, fetchExpenses
};
