<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="white" small fab v-bind="attrs" v-on="on" height="30px" width="30px">
        <v-icon color="#00aff2">mdi-eye</v-icon>
      </v-btn>
    </template>
    <v-card>
      <div class="padding-card">
        <p class="title-modal">Información del pago recibido</p>
        <v-text-field label="Concepto de pago" readonly :value="info.concept"></v-text-field>
        <v-textarea label="Descripción" readonly filled outlined v-html="description"></v-textarea>
        <span class="title-text-info">Importe recibido: </span>
        {{ $currency.format(info.amount) }}
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialog = false"> Cerrar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => ({}),
    }
  },
  computed: {
    description() {
      if (!this.info.concept) return 'No hay datos a mostrar.';
      const result = [];
      if (this.info.maintenanceFeeId) {
        result.push(`Id del plan de mantenimiento: ${this.info.maintenanceFeeId}`);
      }
      result.push(`Id del pago: ${this.info.id}`);
      result.push(`Fecha del pago: ${this.$dayjs(this.info.paymentDate).format('lll')}`);
      result.push(`Fecha de vencimiento: ${this.$dayjs(this.info.paymentDueDate).format('lll')}`);
      result.push(`Cliente: ${this.info.customerId}`);
      result.push(`Condonación de recargo: ${this.info.forgiveSurcharges ? 'Si' : 'No'}`);
      result.push(`Recargo: ${this.info.surcharges ? this.info.surcharges : 0}`);
      result.push(`Estado: ${this.info.status}`);
      result.push(`Método de pago: ${this.info.paymentMethod}`);
      return result.join('<br />') 
    }
  },
  components: {},
  data: () => ({
    dialog: false,
  }),
};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>